<template>
  <DetailDrawer
    :data="broadcastView.data"
  />
  
  <div class="tab-heading-and-action">
    <div>
      <h3>Broadcast</h3>
      <p>Here are the your Broadcast List</p>
    </div> 
    
    
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      
      <!-- <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
         
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
         
        
        <select
          id="BusinessCategory"
          v-model="selectedBusinessCategory"
          name="BusinessCategory"
          @change="selectBusinessCategory"
        >
          <option value="">
            Business Category
          </option>
          <option value="Consumer Services">
            Consumer Services
          </option>
          <option value="Health Care">
            Health Care
          </option>
        </select>
  
        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="FeMale">
            FeMale
          </option>
        </select>
      </div>
       -->

      <router-link
        :to="'/create-broadcast/'"
        class="create-broadcast"
      >
        <fa icon="plus" />  Create Broadcast
      </router-link>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.title`]="{ item }">
      <v-tooltip
        v-if="item.title!='' && item.title.length > 8 "
        :text="item.title"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString((item.title),28) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.title }}
      </div>
    </template> 

  







    

    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.view)"
          />
        </template>
      </v-tooltip>


      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <router-link :to="'/edit-broadcast/'+item.action">
            <fa
              v-bind="props"
              icon="pen-to-square"
              class="pointer"
            />
          </router-link>
        </template>
      </v-tooltip>
      <!-- <fa
        icon="paper-plane"
        class="pointer"
      /> -->
    </template>
  </v-data-table-server>

  
  <!-- delete dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this speaker? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteSpeaker">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete dialog -->

    
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
import DetailDrawer  from '@/components/broadcast/DetailDrawer.vue';
import {ref,reactive,watch,provide,onBeforeMount} from 'vue';
import {getBroadcastList } from '@/service/helper-service.js';
import { truncateString, formatTime, formattedDate} from '@/helper/index.js';



let openMemberDetail = reactive({'status':false,'id':''});
provide('openMemberDetail', openMemberDetail)

const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Title', value: 'title' },
	{ title: 'Recipients', value: 'recipients' },
	{ title: 'Schedule Date', value: 'scheduleDate' },
	{ title: 'Schedule Time', value: 'scheduleTime' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const broadcastView = reactive({
	data: {},
})

const name= ref('');
const	search= ref('');
const	snackbar=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);
const tableHeight = ref(0);
const showFilter = ref(false);

const openFilter = () =>{
	showFilter.value = true;
}

const closeFilter = () =>{
	showFilter.value = false;
}
          
const resetFilter = () =>{
	// selectedBusinessCategory.value = '';
	// selectedGender.value = '';
}


onBeforeMount(() => {
	  if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});



const loadItems = async({ page, itemsPerPage, sortBy })=>{

	offset.value = (page - 1) * itemsPerPage
	loading.value = true

	try {
		const response = await getBroadcastList(`/broadcast?limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});

		console.log('response.data',response.data);

		totalItems.value = response?.data?.totalCount;
		loading.value = false;

		let broadcastData = response?.data?.data;
		console.log('BroadcastData',broadcastData);
		serverItems.value =  broadcastData.map(item => ({
			title: item.title?item.title:'-',
			recipients: item.recipient?item.recipient:'-',
			content: item.content?item.content:'-',
			scheduleDate:item.scheduleDate?formattedDate(item.scheduleDate):'-',
			scheduleTime:item.scheduleTime?formatTime(item.scheduleTime):'-',
			action:item._id?item._id:'-',
			view:item,
		}))

	} catch (error) {
		console.error('chapter error:', error);

	}



}

	

const viewMember = (data) => {
	console.log("data",data);
	broadcastView.data = data;
	openMemberDetail.status = true;
}




</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
.filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  :deep() .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  .filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
  .reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
  .filter-close-btn{width: max-content;
      margin-left: auto;
      display: block;
     cursor: pointer;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
.search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
.search-container input{outline: unset;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .create-broadcast{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
   text-decoration: none;}
.create-broadcast .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() thead tr > th:nth-child(2){width:300px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;color:#007CBA}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-paper-plane{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .drawer-active{
      animation: fade-in 500ms forwards;
	}

 
	
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
  .attachments-container{width: 100%;
    display: flex;
    flex-wrap: wrap;
    }
  .attachments-thumbnail,a.attachments-thumbnail{display: block; width: 80px;height: 80px;margin-right: 12px; object-fit: cover;background-color: gainsboro;border-radius: 5px;margin-bottom: 12px;}

  @media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;flex-direction: column;align-items: unset;}
    .create-broadcast{margin-top: 12px;margin-left: unset;}
	}
</style>






