<template>
  <v-dialog 
    v-model="store.state.chapter.showAddChapterDialog"
    width="400"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            {{ store.state.chapter.chapterFormMode }} Chapter
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-full">
                  <label for="designation">Chapter<span>*</span></label>
                  <Field
                    v-model="chapter"
                    type="text"
                    name="chapter"
                    placeholder="Chapter"
                  />
                  <ErrorMessage name="chapter" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      v-if="store.state.chapter.chapterFormMode=='Add'"
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    
                    <button
                      type="submit"
                      :disabled="isDisabled"
                    >
                      {{ store.state.chapter.chapterFormMode =='Add'?'Done':'Update' }}
                      <fa
                        v-show="spinner===true"
                        icon="spinner"
                        spin
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>
import {ref, inject,provide, watch, onMounted} from 'vue'
import { useStore } from 'vuex';
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';

const store = useStore(); 
const emit = defineEmits(['submit'])



let showSpeakerDialog =  ref(false);


let chapterId = inject('chapterId');




const form  = ref();
const chapter = ref('');

const snackbar= ref(false);
const text= ref('');
const timeout = ref(2000);
const imageUrl = ref('');
const imageName = ref(null);
const responseImageUrl = ref('');
const serverName = ref('');
const tableRefresh = ref(false);
const spinner = ref(false);
const isDisabled = ref(false);
provide('returnTableRefresh',tableRefresh);






watch(() => store.state.chapter.showAddChapterDialog, (newValue) => {
	console.log("newValue", newValue);
	if(newValue){
		let chData = store.state.chapter.editChapterData;
		store.state.chapter.showAddChapterDialog = true;
		if(store.state.chapter.chapterFormMode == 'Edit'){
			
			// profile image

			chapter.value = `${chData.chapter?chData.chapter :''}`;
  
		}
	}
});








  
onMounted(() => {
	serverName.value = window.location.origin;
	imageUrl.value = require(`@/assets/images/avatar.svg`);
  
	console.log("serverName",serverName.value);
	console.log("mounted");
	// file.value.addEventListener('change',()=>{
		
	// 	console.log('reffile.value',file.value.files[0]);
		
	// 	axios
	// 		.post(`${process.env.VUE_APP_SERVICE_URL}/files`,
	// 			{file:file.value.files[0]},
	// 			{headers:{
	// 				'Authorization': `Bearer ${store.state.authentication.token}`,
	// 				'Content-Type': 'multipart/form-data'
	// 			}})
	// 		.then((response) => {
				
	// 			if(response.status === 200){
	// 				console.log(" file response",response.data.data);
	// 				responseImageUrl.value = response?.data?.data;
	// 				imageName.value = response?.data?.data.split('/profile/')[1];
	// 				console.log("fileName",response?.data?.data.split('/profile/')[1]);
	// 				loadImage() 

	// 			}
	// 		})
	// });
})


const uploadProfile = (e) =>{
	console.log("e",e);
	let fileType = e.target.files[0].type;
 
	if(	 fileType === 'image/jpeg' ||
	     fileType === 'image/png' ||
	     fileType === 'image/gif'){
		document.querySelector(".file-error").innerHTML="";
		if(e.target.files[0].size <= 5 * 1024 * 1024){
			


			uploadFormData(e.target.files[0]);

		}else{
			 document.querySelector(".file-error").innerHTML="File size should not exceed 5MB"
		}
   
	}else{
		 document.querySelector(".file-error").innerHTML="Invalid file extension. Please select a JPEG or PNG file"
		

	}

}

const uploadFormData = (file)=>{
	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/files?type=profile`,
			{file:file},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
				'Content-Type': 'multipart/form-data'
			}})
		.then((response) => {
				
			if(response.status === 200){
				console.log(" file response",response.data.data);
				responseImageUrl.value = response?.data?.data;
				imageName.value = response?.data?.data.split('/profile/')[1];
				console.log("fileName",response?.data?.data.split('/profile/')[1]);
				// loadImage() 
				const reader = new FileReader();
				reader.onload = function (e) {
				// document.getElementById('profileImage').src = e.target.result;
					console.log("e.target.result",e.target.result);
					imageUrl.value = e.target.result;
				};
				reader.readAsDataURL(file);

			}
		})
}
  
const loadImage = ()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/files?name=${imageName.value}&type=profile`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
				
			if(response.status === 200){
				console.log("IMG FILE",response.data.data);
				imageUrl.value = `data:image/png;base64,${response.data.data}`;
			}
		})
}




const closeDialog = ()=>{
	chapterId.value ='';
	chapter.value = '';
	form.value.resetForm();
	store.state.chapter.chapterFormMode = 'Add';
	store.state.chapter.showAddChapterDialog = false;
}





const schema = yup.object({
	chapter:yup.string().required('Please enter chapter name')
		.matches(/^[A-Za-z\s]*$/, 'Only letters are allowed')
});



const onSubmit = (data) => {
  

	let method = store.state.chapter.chapterFormMode=='Edit'?'put':'post';
	let url = store.state.chapter.chapterFormMode=='Edit'?`${process.env.VUE_APP_SERVICE_URL}/chapter/${chapterId.value}`:`${process.env.VUE_APP_SERVICE_URL}/chapter/`;

	axios({
		method: method, // 'put' or 'post', dynamically passed
		url: url,
		data: {
			"chapter": data.chapter,
		},
		headers: {
			'Authorization': `Bearer ${store.state.authentication.token}`,
		}
	})
		.then(response => {
			console.log('Response:', response.data);
			snackbar.value=true;
			text.value = `Chapter ${store.state.chapter.chapterFormMode=='Edit'?'updated':'created'} successfully`; 
			form.value.resetForm();
			imageUrl.value = require(`@/assets/images/avatar.svg`);
			closeDialog();
			store.state.chapter.isChapterUpdated = true;


		})
		.catch(error => {
			console.error('Error during the request:', error);
		})
		.finally(() => {
			isDisabled.value = false;
			spinner.value = false;
		});


	
};

const editSpeaker = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/speaker/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
				showSpeakerDialog.value = true;
				openSpeakerDialog.status =true;
				let getMemberDetails = response.data.data[0];
        
				// profile image
				responseImageUrl.value = `${getMemberDetails.image?getMemberDetails.image :''}`;
				imageName.value = responseImageUrl.value?responseImageUrl.value.split('/profile/')[1]:'';
				console.log("imageName.value",imageName.value);
				if(imageName.value!=''){
					loadImage()
				}else{
					imageUrl.value = require(`@/assets/images/avatar.svg`);
				}
				// profile image

				title.value = `${getMemberDetails.title?getMemberDetails.title :''}`;
				firstName.value =  `${getMemberDetails.firstName?getMemberDetails.firstName :''}`; 
				lastName.value =  `${getMemberDetails.lastName?getMemberDetails.lastName :''}`; 
				designation.value = getMemberDetails?.designation || '';
				about.value = getMemberDetails?.about || '';

      
			}
		})
}

const resetForm = ()=>{
	form.value.resetForm();
	emit('submit','success');
	// tableRefresh.status=true;
}

</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */
.profile-upload{margin-bottom: 22px;}
.profile_image img{display: block;
    margin: 0 auto 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;}

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] + span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.title{flex-basis: 30%;margin-right: 18px;}
.firstName{flex-basis: 70%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.title select,.firstName input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{
    color: red;
    font-size: 12px;}
 .file-input{max-width: 249px;
    display: block;
    margin: auto;}
  .file-type{color: gray;font-size: 12px;margin-top: 12px;text-align: center;}
  .file-error{text-align: center;}
/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
</style>
