<template>
   <v-container>
          <h3 style="margin-bottom:15px;">
            Create schedule
          </h3>
        
          <form
            
            @submit.prevent="onSubmitSchedule"
          >
            <div ref="daysContainer">
              <div class="form-row">
                <div class="form-col-half">
                  <label for="broadcastDate">Date </label>
                  <div class="input-group">
                    
                      <flat-pickr
                        v-model="broadcastDate"
                        :config="dateConfig"
                        class="date-form-control"
                        placeholder="Date"
                        name="broadcastDate"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-default"
                          type="button"
                          title="Toggle"
                          data-toggle
                        >
                          <fa icon="clock" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                        <button
                          class="btn btn-default"
                          type="button"
                          title="Clear"
                          data-clear
                        >
                          <i class="fa fa-times" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Clear</span>
                        </button>
                      </div>
                  
                  </div>
                  
                  <!-- <Calendar /> -->
                </div>

                <div class="form-col-half">
                  <label for="broadcastTime">Time</label>
                  <div class="input-group">
                    <flat-pickr
                        v-model="broadcastTime"
                        :config="timeConfig"
                        class="date-form-control"
                        placeholder="Date"
                        name="broadcastTime"
                      />
                    
                      <div class="input-group-append">
                        <button
                          type="button"
                          data-toggle
                        >
                          <fa icon="clock" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                      </div>
                    
                  </div>
                
                  <!-- <Calendar /> -->
                </div>
              </div>
            </div>
              

            
          </form>
        </v-container>
</template>

<script setup>
import {ref} from 'vue';
// import this component
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// theme is optional
// try more themes at - https://flatpickr.js.org/themes/
// import 'flatpickr/dist/themes/material_blue.css';
// localization is optional

const broadcastDate = ref('2022-10-28');
const broadcastTime = ref('');

// Read more at https://flatpickr.js.org/options/
const dateConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	altFormat: 'M j, Y',
	altInput: true,
	dateFormat: 'Y-m-d',        
});

const timeConfig = ref({
	wrap: true, // s
	disableMobile: true,
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true,
	allowSelection:true,
	time_24hr: true      
});


</script>