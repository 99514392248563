<template>
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
    @click.self="viewSpeakerDetail.status = false"
  >
    <div class="view-member-modal drawer-active">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="viewSpeakerDetail.status = false"
        />
       
        <h3>Speaker Details </h3>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Full Name</h5>
          <p>{{ fullName || 'N/A' }}</p>

          <h5>Designation</h5>
          <p>{{ data?.designation || 'N/A' }}</p>

          <h5>About</h5>
          <p>{{ data?.about || 'N/A' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref,inject,watch} from 'vue'
import {listHeading } from '@/helper/index.js';
const viewDialog = ref(false);
const viewSpeakerDetail = inject('viewSpeakerDetail');

const props = defineProps({
	data: {
		type:Object,
		default:()=>{},
		required:false
	},
	fullName: {
		type:String,
		default:''
	},
	
})

watch(viewSpeakerDetail, (newValue) => {
	viewDialog.value = newValue.status;
});


</script>

<style scoped>
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
 .view-modal-action{display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 12px;}
.view-modal-action .fa-pen-to-square{cursor: pointer;margin-left: 12px;}
.view-modal-action img{width: 60px;height:60px;border-radius:50%; margin-left: 12px;object-fit: cover;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:auto;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .inline-action{display: flex;align-items:center;}
    .Active,.Inactive{padding: 5px 6px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border:1px solid #C2C2C2;}   
    .Active{background-color: #CFFFD7;} 
    .Inactive{background-color: #FFCFCF;}  
	.send-credentials{background-color: #007CBA;border:1px solid #007CBA!important;color: #fff !important;}
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
	.activate-button{border: 1px solid #8BC34A!important;
    color: #8BC34A!important;
	}

	.activate-flat-button{
		background-color: #8BC34A!important;color: white;border-color: #8BC34A!important;
	}

.drawer-active{
      animation: fade-in 500ms forwards;
	}
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
	.drawer-inactive{
      animation: fade-out 500ms forwards;
	}
	@keyframes fade-out{
	0%{
	opacity: 1;
	right: 0px;
	}
	100%{
	opacity: 0;
	right: -320px;
	}
	}

  .attachments-container{width: 100%;
    display: flex;
    flex-wrap: wrap;
    }
  .attachments-thumbnail,a.attachments-thumbnail{display: block; width: 80px;height: 80px;margin-right: 12px; object-fit: cover;background-color: gainsboro;border-radius: 5px;margin-bottom: 12px;}
</style>