<template>
  <h2>OTP Verification</h2>
  <h5>
    Enter the OTP sent to <br> {{ store.state.authentication.dialCode }} {{ store.state.authentication.phoneNumber }} <fa
      icon="edit"
      class="pointer"
      @click="editPhone"
    />
  </h5>
  <form @submit.prevent="verifyOtp">
    <div class="otp-inputs">
      <input
        v-for="(digit, index) in otp"
        :key="index"
        v-model="otp[index]"
        type="tel"
        maxlength="1"
        @input="moveToNext(index);showFieldError(index)"
        @keydown="handleKeydown($event, index)"
        @focusout="showFieldError(index)"
      >
    </div>
    
    <h5>
      Didn't receive OTP?  <button
        :disabled="resendBtn"
        class="resend-btn"
        type="button"
        @click="resendOtp"
      >
        Resend
      </button>
      &nbsp;<span> {{ timer }}</span>
    </h5>
    <button
      type="submit"
    >
      <fa
        v-show="spinner===true"
        icon="spinner"
        spin
      />
      Verify OTP
    </button>
  </form>  
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { otpVerfication, generateOtp } from '@/service/helper-service.js';
const store = useStore();
const spinner = ref(false);
const router = useRouter();

const otp = ref(['', '', '', '', '', '']);
const timer = ref('1:00');
const resendBtn = ref(false);


const moveToNext = (index) => {
	if (index < otp.value.length - 1 && otp.value[index].length > 0) {
		document.querySelectorAll('.otp-inputs input')[index + 1].focus();
	}
  
  
};

const handleKeydown = (event, index) => {
	if (event.key === 'Backspace' && otp.value[index] === '') {
		if (index > 0) {
			document.querySelectorAll('.otp-inputs input')[index - 1].focus();
		}
	}

	if (event.key.length === 1 && isNaN(Number(event.key))) {
		event.preventDefault();
	}
};

const showFieldError = (index) => {
	console.log('FCUS',otp.value[index]=='')
	if(otp.value[index]==''){
		document.querySelectorAll('.otp-inputs input')[index].style.borderBottom ='1px solid red' ;
	}else{
		document.querySelectorAll('.otp-inputs input')[index].style.borderBottom ='1px solid gray' ;
	}
};

const isValid = () => {
	let status = true; // Use let so that its value can be updated
	const otpInputs = document.querySelectorAll('.otp-inputs input');
  
	otpInputs.forEach((input, index) => {
		if (input.value === '') {
			otpInputs[index].style.borderBottom = '1px solid red';
			status = false;
		} else {
			otpInputs[index].style.borderBottom = '1px solid gray';
		}
	});

	return status;
};




const editPhone = () =>{
	store.state.authentication.phoneLogin = true;
	store.commit('authentication/setDialCode', '');
	store.commit('authentication/setPhoneNumber', '');
}





const verifyOtp = async() => {
	if(!isValid()){
		return;
	}
  
	const enteredOtp = otp.value.join('');
	spinner.value = true;
	try {
		const response = await otpVerfication('otpVerify', {
			phone:store.state.authentication.phoneNumber,
			otp:enteredOtp,
			type:'login',
			role:'admin'
		});

		if(response.status === 200){
			spinner.value = false;
			console.log('response',response);
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Logged in Successfully',
				color:'success',
				timeout:2000});
          
			store.commit('authentication/setToken', response?.data?.data?.jwt);
			store.commit('authentication/setName', response?.data?.data?.userData.email);
			store.commit('authentication/setID', response?.data?.data?.userData?.userId);
		
			router.push({ path: '/members' });
			setTimeout(()=>{
				store.state.authentication.phoneLogin = true;
			},1000)
			
      
		}
	}finally{
		spinner.value = false;
	}
};

onMounted(() => {
	startTimer()
})

let remainingTime = 30;
function updateTimer() {
	const minutes = Math.floor(remainingTime / 60);
	const seconds = remainingTime % 60;
	const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	timer.value = formattedTime;
	console.log('formattedTime',formattedTime)
                
}

function startTimer() {
	updateTimer();
	const timerInterval = setInterval(() => {
		remainingTime--;
		updateTimer();
		if (remainingTime <= 0) {
			resendBtn.value = false;
			clearInterval(timerInterval);
			remainingTime = 30;             
		}else{
			resendBtn.value = true;
		}
                    
                  
	}, 1000);
}



const resendOtp = async()=>{
	try {
		const response = await generateOtp('mobileLogin', {
			phone:store.state.authentication.phoneNumber,
			dialCode:'+'+store.state.authentication.dialCode,
			role:'admin'
		});

		if(response.status === 200){
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Your OTP has been resent successfully',
				color:'success',
				timeout:2000});
			startTimer();

		}
	} catch (error) {
		console.error('error:', error);
	} 
}

	


</script>

<style scoped>




  #btn-1{
    background-color: white;
    color: #136E9B;
    font-weight: 500;
    font-size: 0.9rem;
}

button{background-color: #007cba;
    border-radius: 5px;
    height: 40px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    color: white;}
  
  button:hover {
    background-color: #0F5C82;
}

h2{font-size: 22px;color:#153448;font-weight: 600;}
h5{margin:12px 0px;font-size: 14px;font-weight: 500;}
h2, h5{text-align: center;}

  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
}

  .otp-inputs input {
    width: 2rem;
    text-align: center;
    font-size: 1.5rem;
    border:unset;
    border-bottom: 1px solid gray;
}

.otp-inputs input:focus{outline: unset;}
.err-msg{color: red;text-align: center;}
.pointer{cursor: pointer;}
.resend-btn{
    display: inline;  
    background-color:unset;
    border-radius: unset;
    height: unset;
    padding: unset;
    width: max-content;
    margin-top: unset;
    color:black;}
.resend-btn:hover{background-color:unset;}  
.resend-btn:disabled{color: gray;cursor: not-allowed;}
</style>
