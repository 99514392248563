<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <!-- Dates{{ store.state.event.eventDates }} -->
      <!-- {{ store.state.event.events }} -->
      <AddTopic
        :event-id="eventId"
        :mode="store.state.event.scheduleFormMode"
        :event-dates="store.state.event.eventDates"
        
        :venue-id="store.state.event.venueId"
      />
      <AddTrackForm
        :title="formTrackMode"
        :confirm-text="'Save'"
        :button-bg="'info'"
        @cancel="openTrackDialog = false"
      />

      <EditSchedule />

      <ConfirmationDialog
        :visible="deleteTrackDialog"
        :body="'Are you sure you want to delete this track? This action cannot be undone.'"
        :confirm-text="'Delete'"
        :button-bg="'danger'"
        @cancel="deleteTrackDialog = false"
        @confirm="deleteTrack"
      />
  
      <ConfirmationDialog
        :visible="deleteEventDialog"
        :body="'Are you sure you want to delete this Event? This action cannot be undone.'"
        :confirm-text="'Delete'"
        :button-bg="'danger'"
        @cancel="deleteEventDialog = false"
        @confirm="deleteEvent"
      />
      <div
        ref="main"
        class="main"
      >
        <div
          class="topic-section"
          :style="topicSectionHeight"
        >
          <div class="topic-action">
            <h3>Topics </h3>
            <div
              class="add-event"
              @click="addTopic"
            >
              <fa icon="plus" /> Add New
            </div>
          </div>
        

          <div
            class="topic-list"
            :style="topicListHeight"
          >
            <TopicCard 
              v-for="(item,index) in store.state.event.topics"
              :key="index"
              :item="item"
              :index="index"
              :class="'external-event'"
              :draggable="true"
              :data-id="item.id"
              :data-duration="item.duration"
              :data-title="item.title"
              :data-type="item.type"
              :data-item="JSON.stringify(item)"
              @dragstart="onEventDragStart($event, item)"
              @delete-topic="deleteTopic(item.id)"
              @edit-topic="editTopic(item.id)"
            />
          </div>
        </div>


     

        <div class="scheduler">
          <div
            class="scheduler-header"
          >
            <h1>Schedule</h1>
            <div class="scheduler-navigation">
              <button
                id="prevBtn"
                @click="prev"
              >
                <fa icon="chevron-left" />
              </button>
              <span>{{ formattedDateWithMonth(displayDate.date) }}</span>
              <button
                id="nextBtn"
                @click="next"
              >
                <fa icon="chevron-right" />
              </button>
            </div>
          </div>
         
          <div
            ref="scheduleElem" 
            class="scheduler-height"
            :style="schedulerHeight"
          >
            <div class="scheduler-body">
              <div
                class="time-column"
              >
                <TimeCell
                  v-for="(item,index) in timeSlot"
                  :key="index"
                  :item="item"
                  :time-step="timeStep"
                  :height="40"
                />
              </div>

              <div
                ref="dateSliderContainer"
                class="date-slide-container"
              >
                <DateColumn
                  v-for="(item,index) in store.state.event.eventDates"
                  :key="index"
                  :item="item"
				  
				  
                  @drop="handleDrop($event)"
                  @dragover="onEventDragOver($event)"
                >
                  <div class="track-container">
                    <TrackColumn
                      v-for="(track,trackIndex) in item.tracks"
                      :key="trackIndex"
                      :track="track"
                      :date="item.date"
                    >
                      <div
                        class="track-cell"
                      >
                        <div
                          v-for="(time,timeIndex) in timeSlot"  
                          :key="timeIndex"
                          class="disabled-time"
                          :class="availabeTimeSlot(item.date,time,addMinutes(time, timeStep))"
                        />	
                        <EventCard
                          v-for="(event,eventIndex) in filteredEvents(item.date,track.id)" 
                          :key="event.id"
                          :event="event"
                          :track="track"
                          :item="item"
                          :style="eventStyles(event)"
                          @dragstart="onEventDragStart($event);allowExtendDrag(item, event, track.id)"
                        />
                      </div>
                    </TrackColumn>
                  </div> 
                </DateColumn>
              </div>
            </div>
          </div>  
		  
          <div class="schedule-action">
            <button
              class="save-as-draft"
              @click="saveAsDraft"
            >
              Save as Draft
            </button>
            <div class="error" />
            <div style="display:flex;">
              <div class="error-message">
                <fa 
                  class="pointer"
                  icon="circle-exclamation"
                />
                <div
			 
                  v-show="errors.length > 0"
                  class="error-dialog"
                >
                  <fa
                    class="close-error-dialog pointer"
                    icon="xmark"
                    @click="errors=[]"
                  />
                  <ul>
                    <li
                      v-for="(item,index) in errors"
                      :key="index"
                    >
                      <fa
                        icon="triangle-exclamation"
                      /> {{ item }}
                    </li>
                  </ul>
                </div> 
              </div>
		  
              <button
                class="publish-event"
                @click="publishEvent"
              >
                Publish Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminPanel>
  </div>
</template>


<script setup>
// @ is an alias to /src
import moment, { max } from 'moment';
import {ref, reactive, provide, onMounted, onBeforeMount, computed, onUpdated, watch } from 'vue';
import { formatTime, formattedDateWithMonth, isoStringDateformat, formattedDateWithOrdinal, parsedDate, isoDateformat, getHours, addMinutes } from '@/helper/index.js';
import AdminPanel from '@/components/ui/AdminPanel.vue'
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {getQueueData, removeTopic, storeTrack, getEventData, getTrackData, removeTrackDetails, addEventTopic, updateSchedule, getSchedule, getProfile} from '@/service/helper-service.js';
import AddTopic from '@/components/event_management/scheduler/AddTopic.vue'
import TopicCard from '@/components/event_management/scheduler/TopicCard.vue'
import AddTrackForm from '@/components/event_management/scheduler/AddTrackForm.vue'
import TimeCell from '@/components/event_management/event_scheduler/TimeCell.vue'
import DateColumn from '@/components/event_management/event_scheduler/DateColumn.vue'
import TrackColumn from '@/components/event_management/event_scheduler/TrackColumn.vue'
import EventCard from '@/components/event_management/event_scheduler/EventCard.vue'
import EditSchedule from '@/components/event_management/scheduler/EditSchedule.vue'

const route = useRoute();
const store = useStore(); 
const eventId = ref(route.params.id);
const formTrackMode = ref('add');

let openTopicDialog = reactive({'status':false});
let openTrackDialog = reactive({'status':false});
let openEditEventDialog = reactive({'status':false});

provide('openTopicDialog',openTopicDialog)
provide('openTrackDialog',openTrackDialog)
provide('openEditEventDialog',openEditEventDialog)

let errors = ref([]);
const deleteTrackDialog = ref(false);
const deleteEventDialog = ref(false);


const addTopic = () =>{
	store.state.event.scheduleFormMode ='Add';
	openTopicDialog.status = true
}




const dates = ref([{date:'2024-08-12',color:'orange',tracks:[{id:'1',name:'Track 1',color:'orange'},{id:'2',name:'Track 2',color:'green'},{id:'3',name:'Track 3',color:'green'}]},
	{date:'2024-06-28',color:'pink',tracks:[{id:'1',name:'Track 1 rr',color:'orange'}]},
	{date:'2024-06-29',color:'yellow',tracks:[{id:'1',name:'Track 1',color:'orange'}]},
	{date:'2024-06-30',color:'green',tracks:[{id:'1',name:'Track 1',color:'orange'}]}
]);
const schedulerHeight = ref('');
const topicSectionHeight = ref('');
const topicListHeight =  ref('');
const scheduleElem = ref(null);
let currentIndex = ref(0);
const displayDate = ref('');
// const dateColumn = ref([]);
const timeSlot = ref([])
const timeStep = ref(15);
const timeCellHeight = ref(40);
const timeRange = ref({start:8,end:16})
// const dateColumnWidth = dateColumn[0].clientWidth;

const dateSliderContainer = ref(null)
const main = ref(null)

const currentEvent = ref(null);
const initialY = ref(0);
const initialHeight = ref(0);
const minuteHeight = ref(2);
const editTrackId = ref('');
const editTrackName = ref('');
const editTrackColor = ref('');
const deleteTrackId = ref('');
const deleteEventId = ref('');

const queueList = reactive([
	{id: "19771",
		duration: 60,
		title: "Starup 2024"},
	{id: "19772",
		duration: 30,
		title: "LCW 2024",}
])

const events = reactive([
	{
		id: "19801",
		start: "2024-06-12 10:00",
		end: "2024-06-12 10:30",
		duration: 30,
		title: "FX 2024",
		track: ['1']
	},
	{
		id: "19810",
		start: "2024-06-27 14:00",
		end: "2024-06-27 15:00",
		duration: 60,
		title: "AI REV",
		track: ['1']
	},
	{
		id: "14946",
		start: "2024-06-27 12:00",
		end: "2024-06-27 12:45",
		duration: 45,
		title: "Tech expo ",
		track: ['1']
	},
	{
		id: "14956",
		start: "2024-06-30 10:00",
		end: "2024-06-30 11:30",
		duration: 90,
		title: "chennai event",
		track: ['1']
	}

]);


onBeforeMount(()=>{
	store.state.event.eventId = eventId.value;
	getEventDates(eventId.value)
})

onMounted(()=>{
	setActive(0)
	
	console.log('dateSliderContainer',dateSliderContainer)
    
})




function updateDateDisplay() {
	displayDate.value = store.state.event.eventDates[currentIndex.value];
	console.log("GET INDEX",store.state.event.eventDates[currentIndex.value])
}


const prev =  () => {
	if (currentIndex.value > 0) {
		currentIndex.value--;
		updateDateDisplay();
		setActive(currentIndex.value)
		moveSlides('prev')
		// updateCarousel(document.querySelectorAll('.date-column')[currentIndex.value].clientWidth)

	// 	let containerDimensions = item.getBoundingClientRect();
		// let containerWidth = containerDimensions.width;
	}
};

const next = () => {
	

	if (currentIndex.value < store.state.event.eventDates.length - 1) {
		currentIndex.value++;
		updateDateDisplay();
		
		setActive(currentIndex.value)
		moveSlides('next')
		// updateCarousel(document.querySelectorAll('.date-column')[currentIndex.value].clientWidth)
			
		
	}
};

const setActive = (currentIndex)=>{
	document.querySelectorAll('.date-column').forEach((item,index) =>{
		if(currentIndex == index){
			item.classList.add('active'); 
		}else{
			item.classList.remove('active');
		}
			
	})
}

const moveSlides = (mode) => {
	let sliderContainerDimension = dateSliderContainer.value.getBoundingClientRect();
	let sliderContainerWidth = sliderContainerDimension.width;
	if(mode=='next'){
		dateSliderContainer.value.scrollLeft += sliderContainerWidth;
	}else{
		dateSliderContainer.value.scrollLeft -= sliderContainerWidth;
	}
};



const getTimeSlot = (start, end, interval)=>{
// Set start and end times
	let startTime = moment(start, 'HH:mm');
	const endTime = moment(end, 'HH:mm');

	// Loop through time with a step of 15 minutes
	while (startTime <= endTime) {
		timeSlot.value.push(startTime.format('HH:mm'));
		startTime.add(interval, 'minutes');
	}
}

const availabeTimeSlot = (date,st,et)=>{
	const matchingDates = store.state.event.eventDates.filter(item => {
		return isoDateformat(item.date) === date;
	}).some(item => {
		if(formatTime(item.startTime) <= st && formatTime(item.endTime) >= et){
			return true;
		}else{
			return false;
		}
	});
	if(matchingDates){
		return 'v-hidden';
	}else{
		return '';
	}
}

const dropInTimeSlot = (date,st,duration)=>{
	const matchingDates = store.state.event.eventDates.filter(item => {
		return isoDateformat(item.date) === date;
	}).some(item => {
		if(formatTime(item.startTime) <= st && formatTime(item.endTime) >= addMinutes(st, duration)){
			return true;
		}else{
			return false;
		}
	});
	return matchingDates;
}

const addTrack = (date) =>{
	store.state.event.trackFormMode = 'Add';
	openTrackDialog.status = true;
	store.state.event.editTrackData = '';
	store.commit('event/setTrackDate',date);
	generateTrackCss();
}

const onEventDragStart = (e)=>{
	console.log('e',e)
	let obj = e.target;
	let eventData = {
		id: obj.dataset.id,
		duration: obj.dataset.duration,
		title: obj.dataset.title,
		type: obj.dataset.type,
		...JSON.parse(obj.dataset.item) 
	};
	console.log('obj.dataset.item',obj.dataset.item);
	
	        let jsonData = JSON.stringify(eventData);
	console.log('jsonData',jsonData)
	        e.dataTransfer.setData("text", jsonData);
 
}

const onEventDragOver = (e)=>{
	e.preventDefault();
}


const allowDrop = (e)=>{
	e.preventDefault();
}
const handleDrop = (e)=>{
	e.preventDefault();
	
	const dropObj = JSON.parse(e.dataTransfer.getData("text"));
	const eventId = dropObj.id;
	
	
	const droppedDate = e.target.closest('.date-column')?.getAttribute("data-date");
	const trackId = e.target.closest('.track')?.getAttribute("data-track-id");
	console.log("trackId",trackId)
	const eventDuration = dropObj?.duration || 30;
	const eventTitle = dropObj.title;
	
	if(dropObj.extendTrackId && (dropObj.extendEventDate == droppedDate)){
		console.log("EXTENDING",dropObj)
		console.log("start track id",dropObj.extendTrackId)
		console.log("end track id",trackId)
		let existingEvent = store.state.event.events.find(e => e.id == dropObj.extendEventId);
		console.log('existingEvent.tracks[0]',existingEvent.tracks)
		
		getIdsInRange(dropObj.extendEventId, existingEvent.tracks[0], trackId, droppedDate)
	}else{
		console.log("NOT EXTENDING",dropObj)
	}
	

	if(eventId){
		
		console.log("trackId",trackId)
		console.log("eventId",eventId)
		console.log("eventDuration",eventDuration)
		console.log("eventTitle",eventTitle)
 
		


		// time update
		const dropY = e.clientY;
		const timeCells = document.querySelectorAll(".time-cell");
		let startTime = null;
		let endTime = null;
		let exactDropTime = null;
		for (const cell of timeCells) {
			const cellRect = cell.getBoundingClientRect();
			if (dropY >= cellRect.top && dropY <= cellRect.bottom) {
				startTime = cell.getAttribute("data-start");
				endTime = cell.getAttribute("data-end");
				console.log("drop startTime",startTime);
			 // Calculate exact drop time
			    const startHour = parseInt(startTime.split(':')[0]);
				const startMinute = parseInt(startTime.split(':')[1]);
				const endHour = parseInt(endTime.split(':')[0]);
				const endMinute = parseInt(endTime.split(':')[1]);

				const totalSlotMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
				const dropPositionWithinCell = (dropY - cellRect.top) / cellRect.height;
				const dropMinutes = totalSlotMinutes * dropPositionWithinCell;
				const roundedDropMinutes = Math.round(dropMinutes / 30) * 30;
				const exactHour = Math.floor(startHour + (startMinute + roundedDropMinutes) / 60);
				const exactMinute = Math.round((startMinute + roundedDropMinutes) % 60);

				exactDropTime = `${exactHour.toString().padStart(2, '0')}:${exactMinute.toString().padStart(2, '0')}`;
				console.log("exactDropTime-->",exactDropTime);

				break;
			}
		}

	


		

		// remove data from queue
		const queueIndex = store.state.event.topics.findIndex(item => item.id === eventId)
		

		// update event data
		let existingEvent = store.state.event.events.find(e => e.id == eventId);
		if(startTime!=null){
			if(trackId){
			    if(dropInTimeSlot(droppedDate,startTime,eventDuration)){
					
					if (existingEvent) {
						if(existingEvent.tracks.length == 1 && !(existingEvent.tracks.length > 1 && isoDateformat(existingEvent.start) != isoDateformat(droppedDate))){
							
						
							// Update the existing event
							existingEvent.start = `${isoDateformat(droppedDate)} ${startTime}`;
							existingEvent.end = `${isoDateformat(droppedDate)} ${addMinutes(exactDropTime, eventDuration)}`;
							existingEvent.title = eventTitle;
							existingEvent.duration = calculateDuration(startTime, addMinutes(exactDropTime, eventDuration));
							//if updating in single tracks
							existingEvent.tracks = [trackId];
							
						}else{
							existingEvent.start = `${isoDateformat(droppedDate)} ${startTime}`;
							existingEvent.end = `${isoDateformat(droppedDate)} ${addMinutes(exactDropTime, eventDuration)}`;
							
							
							console.log('existingEvent',existingEvent)
						}
		
					}else{
						store.state.event.events.push({
							id:eventId,
							start:isoDateformat(droppedDate)+' '+startTime,
							end:isoDateformat(droppedDate)+' '+addMinutes(exactDropTime, eventDuration),
							duration:calculateDuration(startTime, addMinutes(exactDropTime, eventDuration)),
							title:eventTitle,
							tracks:[trackId],
							type:'pending',
							...dropObj
						})
				
			
			
					}
					console.log('EVENTS',store.state.event.events)
					if (queueIndex > -1) store.state.event.topics.splice(queueIndex, 1)
				}
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Please add track to schedule event',
					color:'danger',
					timeout:2000});
			}
		}
	}
}

const allowExtendDrag = (item,event,trackId)=>{
	console.log("item.date",item.date);
	const dateObj = store.state.event.eventDates.find(x => x.date == item.date);
	// const index = dateObj.tracks.findIndex(y => y.id == trackId);
	console.log("dateObj",dateObj);
	const trackIndexArray = [];
	
	for(let i=0;i<dateObj.tracks.length;i++){
		if(event.tracks.includes(dateObj.tracks[i].id)){
			trackIndexArray.push(dateObj.tracks[i])
	  }
	}
	console.log("trackIndexArray",trackIndexArray)
	const trackIndex = trackIndexArray.findIndex(z => z.id == trackId);
	console.log('trackIndex',trackIndex )
	if( trackIndex +1 == event.tracks.length ){
		return 'd-block';
	}
	return 'd-none';
	
}

const extendEventStart = (e)=>{
	e.stopPropagation();
	let obj = e.target;
	console.log('obj.extendEventId',obj?.getAttribute("data-extendEventId"))
	const droppedTrackId = e.target.closest('.track')?.getAttribute("data-track-id");
	const droppedDate = e.target.closest('.date-column')?.getAttribute("data-date");
	let extendData = {
		extendEventDate:droppedDate,
		extendEventId:obj?.getAttribute("data-extendEventId"),
		extendTrackId:obj?.getAttribute("data-extendTrackId"),
		extendDroppedTrackId:droppedTrackId,
		item:obj.dataset.item
	};
	
	        let jsonData = JSON.stringify(extendData);
	        e.dataTransfer.setData("text", jsonData);
	console.log('extendData',jsonData)
	
	
}

const showTrackOption = (e)=>{
	
	let dataId = e.target.getAttribute('data-show-track-id')
	scheduleElem.value.querySelector('#option-dropdown-'+dataId).style.display = "block";
}

const hideTrackOption = (e)=>{
	let dataId = e.target.getAttribute('data-hide-track-id')
	scheduleElem.value.querySelector('#option-dropdown-'+dataId).style.display = "none";
}

const editTrack = (e)=>{
	store.state.event.trackFormMode = 'Edit';
	let trackDate = e.target.getAttribute('data-date');
	openTrackDialog.status = true;
	store.state.event.editTrackData = {
		trackDate:trackDate,
		trackId:e.target.getAttribute('data-id'),
		trackName:e.target.getAttribute('data-name'),
		trackColor:e.target.getAttribute('data-color')
	}
	console.log('editTrackData',store.state.event.editTrackData);
	scheduleElem.value.querySelector('#option-dropdown-'+store.state.event.editTrackData.trackId).style.display = "none";
	store.commit('event/setTrackDate',trackDate);
	
}


const deleteTrackConfirmation = (e) =>{
	deleteTrackDialog.value = true;
	deleteTrackId.value = e.target.getAttribute('data-id');
	scheduleElem.value.querySelector('#option-dropdown-'+deleteTrackId.value).style.display = "none";
	store.commit('event/setTrackDate',e.target.getAttribute('data-date'));
}

const deleteTrack = async ()=>{
	
	console.log('events',events);
    
	try {
		const response = await removeTrackDetails(`/track/${deleteTrackId.value}`);
		if(response.status === 200){
			console.log('Remove track response',response?.data?.data);
			let updateTrackField = store.state.event.eventDates.find(x => x.date == store.state.event.selectedTrackDate).tracks;
			let trackIndex = updateTrackField.findIndex(track => track.id === deleteTrackId.value);

			if (trackIndex !== -1) {
				updateTrackField.splice(trackIndex, 1);
			} 
			deleteTrackDialog.value = false;
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Successfully Deleted',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}

}

const enableEventOption = (e)=>{
	let dataId = e.target.getAttribute('data-eEnabledId')
	scheduleElem.value.querySelector('#event-option-dropdown-'+dataId).style.display = "block";
}

const disableEventOption = (e)=>{
	let dataId = e.target.getAttribute('data-eCloseId');
	scheduleElem.value.querySelector('#event-option-dropdown-'+dataId).style.display = "none";

}

const deleteEventConfirmation = (e) =>{
	deleteEventDialog.value = true;
	console.log('data-id',e.target.getAttribute('data-eDeleteId'));
	deleteEventId.value = e.target.getAttribute('data-eDeleteId')?.split('-')[0];

	scheduleElem.value.querySelector('#event-option-dropdown-'+e.target.getAttribute('data-eDeleteId')).style.display = "none";
	// store.commit('event/setTrackDate',e.target.getAttribute('data-date'));
}

const deleteEvent = async()=>{
	
	try {
		const response = await removeTopic(`/schedule/${deleteEventId.value}`);
		if(response.status === 200){
			console.log('DelteE',response);
			let index = store.state.event.events.findIndex(event => event.id == deleteEventId.value);
			if (index !== -1) {
				store.state.event.events.splice(index, 1);
				deleteEventDialog.value = false;
			}


			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
	
}

const editEventForm = (e) =>{
	 let cardElem = e.target.closest('.event-card');
	 if(cardElem) {
		store.state.event.editScheduleData = JSON.parse(cardElem.dataset.item);
	}
	openEditEventDialog.status = true
}


const saveAsDraft = async()=>{
	console.log('DF',store.state.event.events);
	const drafEvents = store.state.event.events
		.filter(e => (e.type === 'pending' || e.type === 'draft' ))
		.map(e => ({
			_id:e.id,
			title: e.title,
			description: e.description,
			tag: e.tag,
			keywords: e.keywords,
			date: isoDateformat(e.start),
			eventId: eventId.value,
			trackIds: e.tracks,
			trackId: e.tracks[0],
			speakers: e.speakers,
			// ...(e.speakers.length > 0 || { speakers: e.speakers}),
			...(e.start && { startTime:isoStringDateformat(e.start)}),
			...(e.end && { endTime: isoStringDateformat(e.end) }),
			...(e.duration && { duration: e.duration }),
			// ...(e.split && { trackingId: e.tracks }),
			...(e.hallId && { hallId: e.hallId }),
			type:'draft'

		}));

	console.log("drafEvents", drafEvents);

	if(drafEvents.length > 0){
	
		try {
			const response = await updateSchedule('put','/update/schedule',drafEvents);
			if(response.status === 200){
				console.log('Draftresponse',response?.data?.data);
		    store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Saved as Draft',
					color:'success',
					timeout:2000});
			}
		} catch (error) {

		}
	}
}

provide('handleDrop', handleDrop);
provide('allowDrop', allowDrop);
provide('onEventDragStart',onEventDragStart);
provide('allowExtendDrag',allowExtendDrag);
provide('extendEventStart',extendEventStart);
provide('addTrack',addTrack);
provide('editTrack',editTrack);
provide('deleteTrackConfirmation',deleteTrackConfirmation);
provide('showTrackOption',showTrackOption);
provide('hideTrackOption',hideTrackOption);
provide('enableEventOption',enableEventOption);
provide('disableEventOption',disableEventOption);
provide('deleteEventConfirmation',deleteEventConfirmation);
provide('editEventForm',editEventForm);

const handleExtendDrop = (e)=>{
	console.log('extend',e)
}

function onDrag(e) {

	
}



const getIdsInRange = (eventId, startId, endId, date) => {
	const eventObj = store.state.event.eventDates.find(item => item.date === date);
	console.log("55eventObj",eventObj)
	const startIndex = eventObj.tracks.findIndex(track => track.id == startId);
	const endIndex = eventObj.tracks.findIndex(track => track.id == endId);
	console.log("55startId",startId)
	console.log("55endId",endId)
	console.log("55startIndex",startIndex)
	console.log("55endIndex",endIndex)
	
	// if (startIndex === -1 || endIndex === -1 ) {
	// 	throw new Error("Invalid range");
	// }
	let range = [];
	let existingEvent = store.state.event.events.find(e => e.id == eventId);
	console.log("beforeEvent",existingEvent);
	if (startIndex < endIndex) {
		range = eventObj.tracks.slice(startIndex, endIndex + 1);
		existingEvent.tracks =  range.map(track => track.id);
		
	}
	if (startIndex == endIndex) {
		existingEvent.tracks = [startId];
		console.log("startId",startId);
	}

}

const allowExtendFrontDrag = (item, event,trackId)=>{
	// console.log("check id",trackId,typeof(trackId))
	// console.log("event.track Array",event.track)
	// const dateObj = dates.value.find(x => x.date === item.date);
	// const index = dateObj.tracks.findIndex(y => y.id == trackId);
	
	// const trackIndexArray = [];
	
	// for(let i=0;i<dateObj.tracks.length;i++){
	// 	if(event.track.includes(dateObj.tracks[i].id)){
	// 		trackIndexArray.push(dateObj.tracks[i])
	//   }
	// }
	// console.log("trackIndexArray",trackIndexArray)
	// const trackIndex = trackIndexArray.findIndex(z => z.id == trackId);
	// console.log("index ",index,'trackIndex',trackIndex )
	// if(index != 0 && trackIndex == 0 ){
	// 	return 'd-block';
	// }
	return 'd-none';
}

const publishEvent = async()=>{
	
	errors.value = [];


	function validateField(field, fieldName) {
		if (!field || (Array.isArray(field) && field.length === 0)) {
			// console.error(`Error: ${fieldName} is empty`);
			return false;
		}
		return true;
	}

	const publishData = store.state.event.events
		.filter(e => (e.type === 'pending' || e.type === 'draft' || e.type === 'published') )
		.map(e => {
			

			if (!validateField(e.id, 'ID')) errors.value.push(`ID is empty in ${e.title} `);
			if (!validateField(e.title, 'Title')) errors.value.push(`Title is empty in ${e.title} `);
			if (!validateField(e.tag, 'Tag')) errors.value.push(`Tag is empty in ${e.title} `);
			if (!validateField(e.description, 'Description')) errors.value.push(`Description is empty in ${e.title}`);
			if (!validateField(e.keywords, 'Keywords')) errors.value.push(`Keywords is empty in ${e.title}`);
			if (!validateField(e.hallId, 'hall')) errors.value.push(`Hall is empty in ${e.title}`);
			if (!validateField(e.speakers, 'Speakers')) errors.value.push(`Speakers is empty in ${e.title}`);

			// Log all errors.value
			if (errors.value.length > 0) {
				return null;
			}
			console.log("errors",errors.value)
			return {
				_id: e.id,
				title: e.title,
				description: e.description,
				tag: e.tag,
				keywords: e.keywords,
				date: isoDateformat(e.start),
				eventId: eventId.value,
				trackIds: e.tracks,
			    trackId: e.tracks[0],
				speakers: e.speakers,
				...(e.start && { startTime: isoStringDateformat(e.start) }),
				...(e.end && { endTime: isoStringDateformat(e.end) }),
				...(e.duration && { duration: e.duration }),
				...(e.split && { trackingId: e.split }),
				...(e.hallId && { hallId: e.hallId }),
				type: 'published'
			};
		})
		.filter(e => e !== null); // Filter out invalid events

	console.log('publishData',publishData)
	if(publishData.length > 0){
		
		try {
			const response = await updateSchedule('put','/update/schedule',publishData);
			if(response.status === 200){
				console.log('Draftresponse',response?.data?.data);
		            store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Published Successfully',
					color:'success',
					timeout:2000});
			}
		} catch (error) {

		}
	}
}




const calculateDuration = (startTime, endTime) =>{
	if (!startTime || !endTime) return 0;

	const start = moment(startTime, "HH:mm");
	const end = moment(endTime, "HH:mm")

	const duration = moment.duration(end.diff(start)).asMinutes(); // Duration in minutes
	return duration;
}


const eventStyles = (event) =>{
	console.log('eventStyles',event)
	
	const start = moment(event.start, "YYYY-MM-DD HH:mm");
	const end = moment(event.end, "YYYY-MM-DD HH:mm");
	const duration = moment.duration(end.diff(start)).asMinutes();
	console.log('start',start)
	console.log('end',end)
	console.log('duration',duration)
	// console.log('eventData',event)

	const eventHeight = (duration / timeStep.value) * timeCellHeight.value; // Assuming each time slot represents 15 minutes
	// console.log("topPOSITION for",event.title,calculateTopPosition(event.start))
	// console.log("eventHeight for",event.start,eventHeight)
	// console.log("get POSITION",{
	// 	position: "absolute",
	// 	top: calculateTopPosition(event.start),
	// 	height: `${eventHeight}px`,
	// })
	return {
		position: "absolute",
		top: calculateTopPosition(event.start),
		height: `${eventHeight}px`,
	};
}



const calculateTopPosition = (startTime) =>{
	console.log("startTime",startTime)
	const start = moment(startTime, "YYYY-MM-DD HH:mm");
	
	const startHour = start.hours();
	const startMinute = start.minutes();
	
	const startMinutesFromMidnight = startHour * 60 + startMinute;
	const timeRangeStartMinutes = timeRange.value.start * 60; // 8:00 AM
	const minutesIntoRange = startMinutesFromMidnight - timeRangeStartMinutes;
	const topPosition = (minutesIntoRange / timeStep.value) * timeCellHeight.value;
	const roundedTopPosition = Math.round(topPosition / timeCellHeight.value) * timeCellHeight.value + 7;
	return roundedTopPosition + "px";
	// console.log("minutesIntoRange:", minutesIntoRange);
	// console.log("timeCellHeight:", timeCellHeight.value);
	// console.log("Calculated Top Position:", topPosition);

	 // Assuming each time cell has a height of 40px
	//   console.log("startMinutesFromMidnight",startMinutesFromMidnight)
	//   console.log("height",(startMinutesFromMidnight / 30) * timeCellHeight  + "px")
	//return topPosition  + "px"; // Assuming each time slot represents 15 minutes

   

}



const filteredEvents = (date,trackId)=>{
	console.log(date,trackId);
	return store.state.event.events.filter(event => isoDateformat(event.start) == date && event.tracks.includes(trackId));
}








const onResizeMouseDown = (event, eventData)=> {
	console.log("CLIKECD")
	currentEvent.value = eventData;
	initialY.value = event.clientY;
	
	initialHeight.value = event.target.parentElement.clientHeight; // get the height of the event element
	console.log('resize-handle', event.target.closest(".resize-handle"));
	console.log("initialHeight.value",initialHeight.value);
	document.addEventListener('mousemove', onResizeMouseMove);
	document.addEventListener('mouseup', onResizeMouseUp);
	document.addEventListener('mousedown', mouseDown);
	document.addEventListener('mouseout', mouseOut);
}


const mouseDown = (event) =>{
	console.log('mouseDown',event)
}

const mouseOut = (event) =>{
	console.log('mouseOut',event)
	event.target.closest(".topic-card").setAttribute("draggable", false);
	document.removeEventListener('mousemove', onResizeMouseMove);
	document.removeEventListener('mouseup', onResizeMouseUp);
}


const onResizeMouseMove = (event)=> {
	event.target.closest(".topic-card").setAttribute("draggable", false);
	console.log("initialHeight.value",initialHeight.value)
	if (!currentEvent.value) return;
	console.log('event.clientY',event.clientY,'initialY.value',initialY.value)
	const deltaY = event.clientY - initialY.value;
	console.log("deltaY",deltaY);
	const newHeight = initialHeight.value + deltaY;
	console.log("newHeight",newHeight);
	if (newHeight > 40) { // minimum height for an event
		const newDuration = Math.round(newHeight / minuteHeight.value); // calculate new duration
		currentEvent.value.duration = newDuration;
		currentEvent.value.end = `${isoDateformat(calculateNewEndTime(currentEvent.value.end, newDuration))} ${formatTime(calculateNewEndTime(currentEvent.value.end, newDuration))}`;
		console.log('currentEvent.value',currentEvent.value);
		console.log("newDuration",newDuration)
	}
}

const onResizeMouseUp =(event) =>{
	currentEvent.value = null;
	document.removeEventListener('mousemove', onResizeMouseMove);
	document.removeEventListener('mouseup', onResizeMouseUp);
}

const calculateNewEndTime = (end, duration)=>{
	const endTime = new Date(end);
	endTime.setMinutes(endTime.getMinutes() + Math.round(duration));
	return endTime;
}

const loadQueue = async(id)=>{
	try {
		const response = await getQueueData(`/schedule?eventId=${id}&type=pending`);
		if(response.status === 200){
			console.log('Load QUEUE',response);
	
			let mappedTopics = response?.data?.data.map(topic => ({ 'id':topic._id,
				'title':topic.title,
				'tag':topic.tag,
				'description':topic.description,
				'keywords':topic.keywords,
				'eventId':topic.eventId,
				'hallId':topic.hallId,
				...(topic.meetLink && { meetLink: topic.meetLink }),
				'speakers':topic.speakers,
				...(topic?.speakerDetails && { speakerDetails: topic?.speakerDetails }),
				'speakerImages':[],
				'duration':topic.duration,
				'type':topic.type
			}))
			store.state.event.topics = mappedTopics;
			store.state.event.isTopicUpdated = false;
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}

const loadSchedule = async(id)=>{
	try {
		const response = await getSchedule(`/schedule?eventId=${id}`);
		if(response.status === 200){
			console.log('Load Schedule',response);
	
			let mappedTopics = response?.data?.data.map(topic => ({ 'id':topic._id,
				'title':topic.title,
				'tag':topic.tag,
				'description':topic.description,
				'keywords':topic.keywords,
				'eventId':topic.eventId,
				'hallId':topic.hallId,
				'speakers':topic.speakers,
				'start': `${isoDateformat(topic.startTime)} ${formatTime(topic.startTime)}`,
				'end': `${isoDateformat(topic.endTime)} ${formatTime(topic.endTime)}`,
				'duration':topic.duration,
				...(topic.trackIds && { tracks: topic.trackIds }),
				'type':topic.type
			}))
			store.state.event.events = [...mappedTopics];
			console.log('store.state.event.events',store.state.event.events);
			
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}

const deleteTopic = async(id) =>{

	try {
		const response = await removeTopic(`/schedule/${id}`);
		if(response.status === 200){
			console.log('DelteE',response);
			let topicIndex = store.state.event.topics.findIndex(topic => topic.id == id)
			store.state.event.topics.splice(topicIndex,1)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}


const editTopic = (id) =>{
	console.log('id',id)
	store.state.event.scheduleFormMode ='Edit';
	store.state.event.editTopicId = id;
	openTopicDialog.status = true;
}

const getEventDates = async(id)=>{
	try {
		const response = await getEventData(`/event/${id}`);
		if(response.status === 200){
			console.log('GET EVENT response',response?.data?.data);
			console.log(response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),'tracks':[]  })))
			// Mapping the eventDates and tracks
			store.state.event.eventDates =  response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),color:day.color,startTime:day.startTime,endTime:day.endTime,
				tracks:day.trackDetail.map(track => ({
					id: track._id,
					name: track.name,
					color: track.color
				})) }))
			// Finding maximum start & end Time of days
			
			let startTime = response?.data?.data?.[0]?.days.map(day => moment(day.startTime)).sort((a, b) => b - a)[0].toISOString();
			let endTime = response?.data?.data?.[0]?.days.map(day => moment(day.endTime)).sort((a, b) => a - b)[0].toISOString();
			timeRange.value.start = getHours(startTime);
			timeRange.value.end = getHours(endTime);
			getTimeSlot(timeRange.value.start+':00',timeRange.value.end+':00',timeStep.value)
			console.log("timeRangeTest",timeRange.value)
			console.log('start',startTime,'end',getHours(endTime));
			
			store.state.event.uniqeEventId = response?.data?.data?.[0]?.eventId;
			console.log('TEST FOR',response?.data?.data?.[0]?.days.map(day=> ({'id':day._id, 'date': isoDateformat(day.date),color:day.color,tracks:[{id:'1',name:'Track',color:'orange'}] })))
			store.state.event.venueId = response?.data?.data?.[0]?.venueId;
			store.state.event.isCommonMeetLink = response?.data?.data?.[0]?.isCommonMeetLink;
			store.state.event.meetLink = response?.data?.data?.[0]?.meetLink;
			loadSchedule(id)
			updateDateDisplay();
			
			
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}
}


onBeforeMount(() => {
	loadQueue(route.params.id)
	topicSectionHeight.value = `height:${window.innerHeight - 68}px`;
	topicListHeight.value = `height:${window.innerHeight - 168}px`;
	schedulerHeight.value = `height:${window.innerHeight - 220}px`;
	generateTrackCss();
})


const generateTrackCss = ()=>{
	var style = document.createElement('style');
	document.head.appendChild(style);
	let datesList = store.state.event.eventDates;
	for(let i=0;i < datesList.length;i++){
		for(let j=0;j < datesList[i].tracks.length;j++){
			style.sheet.insertRule(`.track-${spliceFirstChar(datesList[i].tracks[j].color)} .event-color-bar::after { background-color: ${datesList[i].tracks[j].color}; }`, 0)
		}
	}
}

const spliceFirstChar = (color)=>{
	  return color[0] == '#'?color.slice(1):color;
}

watch(() => store.state.event.eventDates, (newValue) => {
	 if(newValue){
		generateTrackCss();
	 }
}, { deep: true } );

watch(() => store.state.event.isTopicUpdated, (newValue) => {
	  if(newValue==true){
		loadQueue(route.params.id)
	}
});

  


</script>

<style scoped>

.topic-section{
  width: 33%;
    height: 100vh;
    border-radius: 0 12px 12px 0;
    border: 1px solid #CACACA;
    border-bottom: 0;
    border-left: 0;
	
    }
    .topic-action{border-bottom: 1px solid #9E9E9E;
    margin: 10px;display: flex;justify-content: space-between;align-items: center;
    padding-bottom: 10px;}  
    .topic-list{height: 450px;overflow-y:auto;}
	.scheduler-height{overflow-y:auto;}
    .topic-list::-webkit-scrollbar-track,.scheduler-height::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar,.scheduler-height::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar-thumb,.scheduler-height::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
    .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}

.scheduler{width: 70%;margin: 0 15px;position: relative;}
.scheduler-header {
	display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 10px 20px 0px #C3C3C340;
    margin: 13px 0;
    border-radius: 5px;
    padding: 10px 24px;
	font-family: 'Mulish', sans-serif;
}
.scheduler-header > h1{font-size: 26px;}
.scheduler-navigation{display: flex;align-items: center;background: #F5F5F5;border-radius: 5px;font-size: 14px;}
.scheduler-navigation > button{color:#707070;}
.scheduler-navigation > span{margin-bottom: -3px;}
.scheduler-navigation > button > .fa-chevron-left,.scheduler-navigation > button > .fa-chevron-right{font-size: 14px;}
#dateDisplay {
    padding: 10px;
    font-size: 1.2em;
    margin: 0 10px;
}

button {
    padding: 10px;
    font-size: 1.2em;
    cursor: pointer;
}

/* custom scheduler */
.main{display: flex;}

.date-slide-container{width: 100%;overflow-x: auto;
	scroll-behavior: smooth;display:flex;flex-direction: row; }
		.date-slide-container::-webkit-scrollbar {
  display: none;
}
.date-column{min-width: 200px;}
.disabled-time{
	/* background-color: #c9c9c961; */
	cursor: not-allowed;
    width: 100%;
    margin-top: 0px;
    height: 40px;}
.v-hidden{visibility: hidden; }
/* .time-cell{position: absolute;} */


          
 .track-container{display: flex;width: max-content;
  height: 98%;}
 .track{flex: 0 0 auto;width:200px;position: relative;} 
 .track-container > .track{border-right:1px solid #a5a2a2;}
 .track-container > .track:nth-last-child(1){border-right:unset;}
 .track-cell{position: relative;padding-top: 6px;}
 
.active{box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;opacity: 1;
	display: block;
    /* position: absolute;
    z-index: 1020;
    height: 393px;
    left: 0; */
}
.scheduler-body{position: relative; display: flex;width: 100%;border: 1px solid #e7e7e7;
    box-shadow: 0px 10px 20px 0px #C3C3C340;
	
	/* max-height: 450px; */
}
.time-column{font-size: 13px;margin:53px 6px 0px;width: 78px;}

.schedule-action{
	display: flex;
	justify-content: space-between;
	margin-top: 12px;}
.save-as-draft,.publish-event{height: 43px;font-weight: 800;font-size: 14px;}
.save-as-draft:hover,.publish-event:hover{box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;}
.save-as-draft{border: 1px solid #007CBA;
color: #007CBA;
border-radius: 7px;
padding: 9px 10px;
}
.publish-event{background-color: #007CBA;color: white;border: 1px solid #007CBA;
color: white;
border-radius: 7px;
padding: 9px 10px;}
/* .error-dialog{position: relative;} */
.error-dialog ul {
    list-style: none;
    margin-left: 21px;
    color: black;
    font-weight: 700;
    margin: 0;
}
.error-dialog::before{position: absolute;
    content: '';
    top: 100%;
    right: 10px;
    margin-left: -29px;
    border-width: 11px;
    border-style: solid;
    border-color: #ff0000 transparent transparent transparent;} 
.error-dialog::after{position: absolute;
    content: '';
    top: 100%;
    right: 12px;
    margin-left: -29px;
    border-width: 9px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;} 
.error-dialog ul li {font-size: 14px;}
.error-dialog ul li .fa-triangle-exclamation{color: red;}
</style>

