<template>
  <div
    class="uploaded-container"
  >
    <div
      class="uploaded-list"
      :class="{'upload-success':id!=''}"
    >
      <div v-if="id!=''">
        {{ loadImage(picture) }}
        <img
          :src="imageUrl"
          alt=""
        >
      </div>

      <div v-else>
        <img
          :src="dataUrl"
          alt=""
        >
      </div>
      



      <div class="image-detail">
        <div class="image-caption">
          <v-tooltip
            v-if="captionName.length > 8"
            :text="captionName"
          >
            <template #activator="{ props }">
              <span
                v-bind="props"
              >
                {{ truncatedString(captionName) }} 
              </span>
            </template> 
          </v-tooltip>
          <span v-else>
            {{ truncatedString(captionName) }} 
          </span>
        </div>
        
        <div
          class="image-inline-details pointer"
        >
          <v-tooltip
            :text="id!=''?picture.split('/').pop().substring(36):file.name"
          >
            <template #activator="{ props }">
              <span v-bind="props"> {{ truncatedString(id!=''?picture.split('/').pop().substring(36):file.name) }}</span>  <fa icon="circle-dot" /> 
            </template>
          </v-tooltip>

		

		 
          <p
            v-if="id!=''"
            class="pointer"
            @click="$emit('previewImage',imageUrl,captionName)"
          >
            Preview
          </p>
		  <p
            v-else
            class="pointer"
            @click="$emit('previewImage',dataUrl,captionName)"
          >
            Preview
          </p>
        </div>
      </div>

      <div class="inline-action">
        <div
          v-if="id!=''"
          class="file-size"
        >
          {{ getFileSizeFromBase64(imageUrl) }}
        </div>
        <div
          v-else
          class="file-size"
        >
          {{ getFileSizeFromBase64(dataUrl) }}
        </div>
        <fa
          v-show="id!=''"
          icon="pen-to-square" 
          @click="$emit('editList',index)"
        />
        <fa
          icon="trash"
          @click="$emit('deleteList',index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import { useStore} from 'vuex';
import axios from 'axios';

const store = useStore(); 
const props = defineProps({
	index: {
		type:Number,
		default:0
	},
	captionName: {
		type:String,
		default:''
	},
	picture:{
		type:String,
		default:''
	},
	dataUrl:{
		type:String,
		default:''
	},
	file:{
		type:Object,
		default:()=>{}
	},
	id:{
		type:String,
		default:''
	}
})





const emit = defineEmits(['deleteList','editList','previewImage']);

const truncatedString = (string)=>{
	let result = string.substring(0, 10);
	// Add dots if the original string length is longer than 8 characters
	if (string.length > 8) {
		result += "..";
	}
	return result;
}

const imageUrl = ref(require(`@/assets/images/thumbnail.png`));



function loadImage(fileName){
  
	if(fileName){
		axios
			.get(`${process.env.VUE_APP_SERVICE_URL}/files?name=${fileName}&type=gallery`,
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
					//   albumArray.value.push({'id':id,'albumName':albumName,'coverPicture':'data:image/jpeg;base64,'+response?.data?.data})
					imageUrl.value = 'data:image/jpeg;base64,'+response?.data?.data;
				}
			})
			.catch((error)=>{
				
			});
  
	}else{
		//   albumArray.value.push({'id':id,'albumName':albumName,'coverPicture':require(`@/assets/images/thumbnail.png`)})
	}
  
}






const getFileSizeFromBase64 = (base64String)=>{
	// Remove the metadata part of the base64 string (if it exists)
	const base64WithoutMetadata = base64String.split(',')[1] || base64String;
	// Decode the base64 string into binary data
	const binaryData = atob(base64WithoutMetadata);
	// Get the length of the binary data in bytes
	const fileSizeInBytes = binaryData.length;
	// Convert bytes to kilobytes
	const fileSizeInKB = fileSizeInBytes / 1024;
	// Convert kilobytes to megabytes
	const fileSizeInMB = fileSizeInKB / 1024;
	if (fileSizeInMB < 1) {
		return fileSizeInKB.toFixed(2) + " KB";
	} else {
		return fileSizeInMB.toFixed(2) + " MB";
	}
}
</script>

<style scoped>
.uploaded-list{box-shadow: 0px 4px 7px 0px #CDCDCD40;display: flex;align-items: center;padding: 9px;
    border-radius: 8px;margin-bottom: 18px;border: 1px solid gainsboro;}
    .uploaded-list img{width:50px;height:50px;border-radius: 8px;}
    .image-detail{margin-left: 12px;}
    .image-caption{color: black;
    font-weight: 700;
    font-size: 18px;
    margin-top: -11px;}
    .image-inline-details{font-size: 14px;}
    .image-inline-details .fa-circle-dot{font-size:8px;margin: 2px 10px 0px 10px;color:gray;}
    .image-inline-details p{color: #0F91D2;}
    .image-inline-details{display: flex;align-items: center;}
    .inline-action{display: flex;margin-left: auto;}
    .inline-action .fa-pen-to-square,.inline-action .fa-trash{font-size: 14px;cursor: pointer;}
    .inline-action .fa-trash{color:#FF0000;margin-left: 12px}
    .inline-action .file-size{margin-right: 12px;font-size: 12px;}
	.pointer{cursor: pointer;}
	.upload-success{background-color:#f7fcfb;}
</style>