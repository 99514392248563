<template>
  <AddVenue />
  <AddHall />
  <div class="tab-heading-and-action">
    <div>
      <h3>Venue Management </h3>
    </div> 
    
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    
      <div
        class="add-venue"
        @click="addVenueDialog"
      >
        <fa icon="plus" /> Add Venue
      </div>
    </div>
  </div>
 
  <v-data-table-server
  
    v-model:items-per-page="itemsPerPage"
    header-class="custom-header-class"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="action"
    
    @update:options="loadItems"
  >
    <template #[`item.about`]="{ item }">
      <div :class="item.about">
        {{ limitStringLength(item.about,50) }}
      </div>
    </template>

    <template #[`item.action`]="{ item }">
      <div
        class="add-hall"
        @click="addHall(item.action)"
      >
        <fa icon="plus" /> Add Hall
      </div>

      
      <fa
        icon="pencil"
        class="pointer"
        @click="editVenue(item.view)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteVenueConfirmation(item.action)"
      />
    </template>

    
 
    <template #expanded-row="{ columns, item }">
      <!-- <td colspan="5"> -->
      <!-- <table class="hall-table">
      <thead class="sub-table"> -->
      <tr class="sub-table">
        <th style="border-bottom: unset;" />
        <th style="border-bottom: unset;">
          Halls
        </th>
        <th style="border-bottom: unset;text-align: right;">
          Capacity
        </th>
        <th style="border-bottom: unset;">
          Action
        </th>
      </tr>
      <!-- </thead> -->
    
 
      <template
        v-if="item.hall_list.length > 0"
      >
        <tr
          v-for="list in item.hall_list"
          :key="list._id"
          class="hall-row"
        >
          <td>
            <div class="tree_label" />
          </td> 
          <td>
            {{ list.name }}
          </td>
          <td>
            {{ list.capacity }}
          </td>
          <td>
            <fa
              icon="pencil"
              class="pointer"
              @click="editHall(list._id)"
            />
            <fa
              icon="trash"
              class="pointer"
              @click="deleteHallConfirmation(list._id)"
            />
          </td>
        </tr>
      </template> 
      
      <!-- </table> -->
      <!-- </td> -->
 
      <template v-else>
        <tr>
          <td
            colspan="4"
            style="text-align: center;"
          >
            No Data
          </td>
        </tr>
      </template> 
    </template>
  </v-data-table-server> 


  <!-- Delete Venue dialog -->
  <v-dialog 
    v-model="deleteVenueDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteVenueDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this venue? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteVenueDialog = false">
          Cancel
        </button>
        <button @click="deleteVenue">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Delete Venue dialog -->


  
  <!-- Delete Hall dialog -->
  <v-dialog 
    v-model="deleteHallDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteHallDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this hall? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteHallDialog = false">
          Cancel
        </button>
        <button @click="deleteHall">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Delete Hall dialog -->

    
      
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
  >
    <div class="view-member-modal">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="viewDialog = false"
        />
        <h3>Speaker Details</h3>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Full Name</h5>
          <p>{{ speakerView.fullName }}</p>
          <h5>Designation</h5>
          <p>{{ speakerView.designation }}</p>
          <h5>About</h5>
          <p>{{ speakerView.about }}</p>
        </div>
      </div>
    </div>
  </div>


  <!-- Edit Dialog -->
  <v-dialog 
    v-model="editHallDialog"
    width="550"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeEditDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;font-size: 22px;">
            Edit Hall
          </h3>
          <div class="form-card">
            <Form
              ref="editForm"
              :validation-schema="editHallSchema"
              @submit="onHallUpdate"
            >
              <div class="form-row">
                <div class="hall-col">
                  <label
                    for="editHallName"
                    style="margin-top: 12px;"
                  >Hall</label>
                  <Field
                    v-model="editHallName"
                    type="text"
                    name="editHallName"
                    placeholder="Hall"
                  />
                  <ErrorMessage name="editHallName" />
                </div>

                <div class="capacity-col">
                  <label
                    for="editCapacity"
                    style="margin-top: 12px;"
                  >Capacity</label>
                  <Field
                    v-model="editHallCapacity"
                    type="text"
                    name="editHallCapacity"
                    placeholder="Capacity"
                  />
                  <ErrorMessage name="editHallCapacity" />
                </div>

                <button
                  class="update-hall"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
            <br>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- Edit Dialog -->


  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
import AddVenue from '@/components/venue_management/AddVenue.vue';
import AddHall from '@/components/venue_management/AddHall.vue';
import {ref,reactive,watch,provide,onMounted,computed,onBeforeMount} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import { useStore} from 'vuex';
import axios from 'axios';




const store = useStore(); 

const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: '', value: 'data-table-expand' },
	{ title: 'Venue', value: 'venue' },
	{ title: 'No. of Halls', value: 'no_of_halls' },
	{ title: 'Total Capacity', value: 'total_capacity' },
	// {title:'hall_list',value:'hall_list'},
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const expanded = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const speakerId=ref('');
const venueId = ref('');
const hallId=ref('');
const speakerView = reactive({
	fullName:'',
	designation:'',
	about:''
})

const name= ref('');
const calories= ref('');
const	search= ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteHallDialog=ref(false);
const deleteVenueDialog = ref(false);
const editHallDialog = ref(false);
const editHallName = ref('');
const editHallCapacity = ref('');
const	text= ref('');
const	timeout= ref(2000);
let tableRefresh = ref(false);
const tableHeight = ref(0);


let openVenueDialog = reactive({'status':false});
let openHallDialog = reactive({'status':false});
provide('openVenueDialog',openVenueDialog)
provide('openHallDialog',openHallDialog)  

provide('venueId',venueId)

// let formSubmitted =  inject('formSubmitted');

const editHallSchema = yup.object({
	editHallName:yup.string().optional(),
	editCapacity: yup.string().optional()
});

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});


tableRefresh = computed(() => {
	return store.state.venue.tableRefresh;
})

watch(tableRefresh, (newValue) => {
	console.log("tableRefresh Var",newValue);
	if(newValue===true){
		search.value = String(Date.now());
	}

	
});

onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

onMounted(() => {
	console.log("onMounted tablerefresh",tableRefresh.value)
})



// watchEffect(tableRefresh.value,(val) => {
//       console.log('new returnTableRefresh', val.value)
//     })




const addVenueDialog = () =>{
	store.state.venue.showAddVenueDialog = true;
	store.state.venue.venueEditMode = false;
	console.log("openVenueDialog.value",openVenueDialog.status);
}

const addHall = (id) =>{
  
	// openHallDialog.status = true;
	//  console.log("openVenueDialog.value",openVenueDialog.status);
	venueId.value = id;
	store.state.venue.addHallPopup = true;
}


const editHall = (id)=>{
	console.log(id);
	hallId.value =id;
	editHallDialog.value = true;
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/hall/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			
			console.log("edit response",response);
			if(response.status === 200){
        
				let getHallDetails = response.data.data[0];
				editHallName.value = `${getHallDetails.name?getHallDetails.name :''}`;
				editHallCapacity.value = `${getHallDetails.capacity?getHallDetails.capacity :''}`;
				venueId.value = `${getHallDetails.venueId?getHallDetails.venueId :''}`;
			  
        

			}
		});
}

const hallStatus = computed(() => {
	return store.state.venue.addHallPopup;
})


watch(hallStatus, (newValue) => {
	console.log('newValue',newValue)
})






const loadItems  = ({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true
      
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/venue?limit=${itemsPerPage}&offset=${offset.value}`,{
			params:{
				value:name.value
			}, 
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			serverItems.value = []
			console.log('response.data.title',response.data);
			// serverItems = response.data.data;
			totalItems.value = response.data.totalCount;
			loading.value = false;

          
			let venueData = response.data.data;
			venueData.forEach((item,index) => {
				serverItems.value.push(
					    {venue:  item.name?item.name:'-',
						no_of_halls: item.hallCount?item.hallCount:'-',
						total_capacity: item.totalCapacity?item.totalCapacity:'-',
						    hall_list:item.hallDetails?item.hallDetails:'-',
						action:item._id,
						view:item})
			});

			store.state.venue.tableRefresh = false;


		})
		.catch((error)=>{
			
		});

        
}

const	deleteVenueConfirmation = (item) =>{
	venueId.value = item;
	deleteVenueDialog.value=true;
}



const deleteVenue = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/venue/${venueId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteVenueDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now()); 
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Deleted Venue Successfully',
					color:'success',
					timeout:2000});
						
			}
		})
}


const deleteHall = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/hall/${hallId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteHallDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now());
				snackbar.value=true;
				text.value = "Deleted Hall Successfully"; 
						
			}
		})
}

const onHallUpdate = (data) => {

	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/hall/${hallId.value}`,
			{
				"name": data.editHallName,
				"capacity":data.editHallCapacity,
				"venueId": venueId.value
			},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				closeEditDialog()
				snackbar.value=true;
				text.value = "Hall updated successfully"; 
				
				
      
			}
		})
	
	//  hallList[hallIndex.value].name = data.editname;
	// closeEditDialog()
}

const closeEditDialog = ()=>{
	editHallName.value = '';
	editHallCapacity.value = '';
	editHallDialog.value = false;
}
	


const viewSpeaker = (id)=>{
	speakerId.value = id;
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/speaker/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			viewDialog.value=true;
			console.log("response",response);
			let getMemberDetails = response.data.data[0];
			speakerView.fullName = `${getMemberDetails.title?getMemberDetails.title :''} ${getMemberDetails.firstName?getMemberDetails.firstName :''} ${getMemberDetails.lastName?getMemberDetails.lastName :''}`;
			speakerView.designation = getMemberDetails?.designation || 'N/A';
			speakerView.about = getMemberDetails?.about || 'N/A';
					
		})
}


const editVenue = (data)=>{
	console.log('editData',data);
	// openVenueDialog.status = true;
	store.state.venue.showAddVenueDialog = true;
	store.state.venue.venueFormMode = 'Edit';
	store.state.venue.editVenueData = data;
	venueId.value = data._id;
}

const limitStringLength = (inputString, maxLength)=> {
	if (inputString.length > maxLength) {
		// If the string is longer than the specified maxLength, truncate it
		// and add '...' at the end.
		return inputString.slice(0, maxLength) + '...';
	} else {
		// If the string is within the specified maxLength, return it as is.
		return inputString;
	}
}




const messageFromChild = ref('');

const receiveMessageFromChild = (message) => {
	// Receive the message from the child component
	messageFromChild.value = message;
	console.log("message",message);
};


const deleteHallConfirmation = (id) => {
	hallId.value = id;
	deleteHallDialog.value = true;
};

</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
.search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
.search-container input{outline: unset;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-venue{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-venue .fa-plus{margin-right: 8px;}
.add-hall{ background: #007cba;
    color: white;
    display: inline-block;
    padding: 6px 9px;
    margin-right: 18px;
    border-radius: 4px;
    cursor: pointer;}

 .update-hall{ 
  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
    height: 40px;
 }   

    /* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.form-row{display: flex;align-items:flex-end;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
.v-card-text h3{display: flex;align-items: center;}

.hall-col{flex-basis: 65%;margin-right: 18px;}
.capacity-col{flex-basis: 20%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.hall-col input,.capacity-col input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}

.v-table :deep() thead tr > th:nth-last-child(1){text-align: center;width: 250px;}
 .v-table :deep() thead tr > th:nth-last-child(1) .v-data-table-header__content{text-align: center;} 
.v-table :deep() thead tr > th:nth-child(3){width: 110px;}
.v-table :deep() thead tr > th:nth-child(4){width: 130px;}
/* .v-table :deep() thead tr > th:nth-last-child(1) .v-data-table-header__content{margin: auto;} */
.v-table :deep() tbody tr > td{text-align: left;}
/* .v-table :deep() tbody tr > td:nth-last-child(1){text-align: center;} */
.v-table :deep() tbody tr > td:nth-child(3),.v-table :deep() tbody tr > td:nth-child(4){text-align: right;}
.v-table :deep() tbody tr:not(.hall-row-wrapper):nth-child(odd){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pencil{margin-right: 15px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .v-table :deep() thead tr > th:nth-child(1){width: 10px!important;text-align: right;}
    .v-table :deep() tbody tr > td:nth-child(1) {text-align: right;}
    .v-table :deep() tbody tr > td .v-btn--icon.v-btn--density-default {
    width:12px;}
    .v-table :deep() tbody tr > td .v-btn__overlay, .v-btn__underlay{display: none;}
    .v-table :deep() tbody tr > td .v-btn::after{display: none;}

    table.hall-table{width: 100%;background-color: unset;}
   
  thead.sub-table{background-color: unset!important;color:black;}
  .sub-table th{font-weight: 800!important;border-bottom: unset!important;height: 34px!important;}
  
  .sub-table + tr > td:nth-child(1) .tree_label:before{height: 3.1em!important;
    top: -61px;
}




  /* .sub-table th:nth-child(1){} */
  /* :deep() .sub-table > td:nth-child(1){background-color: #007CBA!important;} */
  .hall-row > td:nth-child(1){border-bottom: unset!important;}
  .hall-row{background-color: unset!important;}
  .hall-name{width:100%;background-color: #EC1B1B;}
   /* .v-table :deep() thead th:nth-child(1){width:15px!important;} */
   .tree_label{position: relative;}
   .tree_label:after {
    position: absolute;
    top: -37px;
    left: 7px;
    display: block;
    height: 2.6em;
    width: 1em;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-radius: 0 0 0 0.4em;
    content: '';
  }

  .tree_label:before {
    position: absolute;
    top: -83px;
    left: 0.5em;
    display: block;
    height: 3.6em;
    width: 1em;
    border-left: 1px solid #e0e0e0;
    content: '';
  }
   
  tr.hall-row > td:nth-last-child(1){text-align: left!important;}
</style>






