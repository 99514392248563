<template>
  <AddSpeaker />
  
  <DetailDrawer
    :data="speakerView.data"
    :full-name="speakerView.fullName"
  />
  <div class="tab-heading-and-action">
    <div>
      <h3>Speakers List </h3>
      <p>Here are the your Speakers List</p>
    </div> 
    
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    
      <div
        class="add-speakers"
        @click="clickSpeakerDialog"
      >
        <fa icon="plus" /> Add Speakers
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.about`]="{ item }">
      <div :class="item.about">
        {{ limitStringLength(item.about,50) }}
      </div>
    </template>

    <template #[`item.action`]="{ item }">
      <fa
        icon="eye"
        class="pointer"
        @click="viewSpeaker(item.view)"
      />
      <fa
        icon="pen-to-square"
        class="pointer"
        @click="editSpeaker(item.view)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteSpeakerConfirmation(item.action)"
      />
    </template>
  </v-data-table-server>

  
  <!-- delete dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this speaker? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteSpeaker">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete dialog -->
</template>





<script setup>
import AddSpeaker from '@/components/speakers_list/AddSpeaker.vue';
import {ref,reactive,watch,provide,inject, onBeforeMount} from 'vue'
import DetailDrawer  from '@/components/speakers_list/DetailDrawer.vue';
import { useStore } from 'vuex';
import axios from 'axios';




const store = useStore(); 
const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Speaker Name', value: 'speaker_name' },
	{ title: 'Designation', value: 'designation' },
	{ title: 'About', value: 'about' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const speakerId=ref('');
const mode =  ref('add');
const speakerView = reactive({
	fullName:'',
	data:{}
})

const name= ref('');
const	search= ref('');
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	timeout= ref(2000);
const tableHeight = ref(0);



let openSpeakerDialog = reactive({'status':false});
let viewSpeakerDetail = reactive({'status':false,'id':''});
provide('openSpeakerDialog',openSpeakerDialog)
provide('viewSpeakerDetail', viewSpeakerDetail)
provide('speakerId',speakerId)
provide('mode',mode)
// let formSubmitted =  inject('formSubmitted');

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});

watch(() => store.state.speaker.isSpeakerUpdated, (newValue) => {
	if(newValue==true){
		search.value = String(Date.now())
		store.state.speaker.isSpeakerUpdated = false;
	}

});




const clickSpeakerDialog = () =>{
	store.state.speaker.showAddSpeakerDialog = true;
}

onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

const loadItems  = ({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true
      
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/speaker?limit=${itemsPerPage}&offset=${offset.value}`,{
			params:{
				value:name.value
			}, 
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			serverItems.value = []
			console.log('response.data.title',response.data);
			// serverItems = response.data.data;
			totalItems.value = response.data.totalCount;
			loading.value = false
          
			let memberData = response.data.data;
			memberData.forEach((item,index) => {
				let fullName =  `${item.title?item.title :''} ${item.firstName?item.firstName :''} ${item.lastName?item.lastName :''}`;
				serverItems.value.push({speaker_name: fullName,
					designation: item.designation?item.designation:'-',
					about: item.about?item.about:'-',
					action:item._id,
					view:item})
			});


		})
		.catch((error)=>{
			
		});

    

        
}

const deleteSpeakerConfirmation = (item) =>{
	speakerId.value = item;
	deleteDialog.value=true;
}

const deleteSpeaker = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/speaker/${speakerId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now());
				
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Deleted Successfully',
					color:'success',
					timeout:2000});
						
			}
		})
}

	

const viewSpeaker = (data)=>{
	console.log(data);
	speakerView.data = data;
	if(data?.title){
		speakerView.fullName = data?.title +' ';
	}

	if(data?.firstName){
		speakerView.fullName+= data?.firstName;
	}

	if(data?.middleName){
		speakerView.fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		speakerView.fullName+=' '+data?.lastName;
	}
	viewSpeakerDetail.status = true;
}


const editSpeaker = (data)=>{
	store.state.speaker.showAddSpeakerDialog = true;
	store.state.speaker.speakerFormMode = 'Edit';
	speakerId.value = data._id;
	store.state.speaker.editSpeakerData = data;
}

const limitStringLength = (inputString, maxLength)=> {
	if (inputString.length > maxLength) {
		// If the string is longer than the specified maxLength, truncate it
		// and add '...' at the end.
		return inputString.slice(0, maxLength) + '...';
	} else {
		// If the string is within the specified maxLength, return it as is.
		return inputString;
	}
}





</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
.search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
.search-container input{outline: unset;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th:nth-last-child(1){width: 152px;
  text-align: center;}
  .v-table :deep() thead tr > th:nth-last-child(1) .v-data-table-header__content{justify-content: center;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td:nth-last-child(1){text-align: center;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}

</style>






