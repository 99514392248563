<template>
  <div class="v-container">
    <h3>{{ formHeading }}</h3>
  </div>
  
  <Form
    ref="form"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <div class="form-container">
      <div class="form-card">
        <div class="form-sets">
          <h3>Message</h3>
          
          <!-- test -->


          <div class="form-col-full">
            <label for="title">Title</label>
            <Field
              v-model="title"
              name="title"
              type="text"
              placeholder="Title"
            />
            <ErrorMessage name="title" /> 
          </div>
           
          <div class="form-col-full">
            <label for="message">Message<span>*</span> </label>
            <Field
              id="message"
              v-slot="{field}"
              :ref="messageArea"
              v-model="message"
              name="message"
              type="text"
              as="textarea"
              style="resize: none; overflow-y: hidden;"
              @input="adjustTextareaHeight"
            />
            <ErrorMessage name="message" />
          </div> 
      
          <div
            class="form-col-full"
            style="margin-bottom: 0;"
          >
            <label for="gallery">Gallery </label>
  
            <Field
              v-model="gallery"
              name="gallery"
              as="select"
            >
              <option
                value=""
              >
                Gallery
              </option>
              <option
                v-for="item in albumArray"
                :key="item._id"
                :value="item._id"
              >
                {{ item.albumName }}
              </option>
            </Field>
        
            <ErrorMessage name="gallery" />
          </div>

          <h4>Select the Recipient group? <span>*</span></h4>
          <div style="display:flex;">
            <div class="radio-group">
              <input
                id="eventBased"
                v-model="groupType"
                type="radio"
                name="groupType"
                value="Event Based"
              >
              <label for="eventBased">Event Based</label>
            </div>
            <div class="radio-group">
              <input
                id="virtualGroup"
                v-model="groupType"
                type="radio"
                name="groupType"
                value="Virtual Group"
              >
              <label for="virtualGroup">Virtual Group</label>
            </div>
          </div>

          <div
            v-if="groupType === 'Event Based'"
            class="event-based-section"
          >
            <div class="form-row">
              <div class="form-col-full">
                <label for="eventRecipients">Event Recipients<span>*</span> </label>
                <Field
                  id="eventRecipients"
                  v-model="eventRecipients"
                  name="eventRecipients"
                  as="select"
                >
                  <option
                    value=""
                  >
                    Select Event
                  </option>
                  <option
                    v-for="item in eventList"
                    :key="item._id"
                    :value="item._id"
                  >
                    {{ item.name }}
                  </option>
                </Field>
                <ErrorMessage name="eventRecipients" />
              </div>
            </div>  

            <div class="form-row">
              <div class="form-col-full">
                <label for="forWhom">For Whom?</label>
                <Field
                  id="forWhom"
                  v-model="forWhom"
                  name="forWhom"
                  as="select"
                >
                  <option value="Registered Users">
                    Registered Users
                  </option>
                </Field>
                <ErrorMessage name="forWhom" />
              </div>
            </div> 

            <div class="form-row">
              <div class="form-col-full">
                <label for="industry">Industry </label>
                <MultiSelect
                  id="industry"
                  v-model="selectedIndustry"
                  display="chip"
                  :options="industryList"
                  option-label="name"
                  placeholder="Industry"
                  :max-selected-labels="3"
                  style="width:100%"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-col-full">
                <label for="chapter">Chapter </label>
                <MultiSelect
                  id="chapter"
                  v-model="selectedChapter"
                  display="chip"
                  :options="chapterList"
                  option-label="name"
                  placeholder="Chapters"
                  :max-selected-labels="3"
                  style="width:100%"
                />
              </div>
            </div>
          </div>

          <div
            v-if="groupType === 'Virtual Group'"
            class="virtual-group-section"
          >
            <div class="form-row">
              <div class="form-col-full">
                <label for="eventRecipients">Search Users</label>
                <Field
                  id="eventRecipients"
                  v-model="eventRecipients"
                  name="eventRecipients"
                  as="select"
                >
                  <option value="Networking Nexus">
                    Networking Nexus
                  </option>
                </Field>
                <ErrorMessage name="eventRecipients" />
              </div>
            </div> 
          </div>
        </div>
      </div>
    
      
    
      <div
        v-show="gallery==''"
        class="form-card"
      >
        <v-skeleton-loader
          v-if="broadcastId && isConverting"
        
          height="240"
          type="image"
        />
        <div
          v-else
          class="form-sets"
        >
          <h3>Files Attachments </h3>
          <div style="position: relative;">
            <file-pond
              ref="pond"
              name="file"
              class-name="my-pond"
              label-idle="<p class='upload-icon'>Drag or upload your files</p>"
              allow-multiple="true"
              accepted-file-types="image/jpeg, image/png, application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-file-size="5MB"
              :files="myFiles"
              :server="serverOptions"
              credits="false"
              image-preview-min-height="44"
              image-preview-max-height="100"
              allow-file-size-validation="true"
              label-max-file-size-exceeded="File is too large"
              @init="handleFilePondInit"
            />
            <span class="file-limitation">Files must be under 5 MB and can be in image (JPG, PNG), PDF, or DOC format</span>
          </div>
        </div>
      </div>
    </div>

  
    <br>  
    <div class="form-row">
      <div class="form-col-full">
        <div class="form-actions">
          <button
            v-show="!broadcastId"
            type="button"
            @click="resetForm"
          >
            Reset
          </button>

          <button
            type="button"
            @click="openSchedule"
          >
            Schedule
          </button>

          <button
            v-show="broadcastId"
            type="button"
            @click="cancelUpdate"
          >
            Cancel
          </button>
          <button
            type="submit"
            :disabled="saveBroadcast?true:false"
          >
            Send  
          </button>
        </div>
      </div>
    </div>
  </Form>
  
     
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}
  
    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->



 
        
  <!-- Schedule -->
  <div
    v-if="store.state.broadcast.showScheduleDialog"
    class="dialog-overlay"
  >
    <div
      class="dialog schedule-form"
    >
      <div
        class="close-icon"
        @click="closeScheduleDialog()"
      >
        <fa icon="close" />
      </div>
      <h2>{{ scheduleHeading }}</h2>  
      <form @submit.prevent="onSubmitSchedule">
        <div class="dialog-body">
          <div class="form-row">
            <div class="form-col-half">
              <label for="broadcastDate">Date<span>*</span> </label>
              <div class="input-group">
                <flat-pickr
                  id="broadcastDate"
                  v-model="broadcastDate"
                  :config="bcDateConfig"
                  class="date-form-control"
                  placeholder="Date"
                  name="broadcastDate"
                  @change="validateDate()"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-default"
                    type="button"
                    title="Toggle"
                    data-toggle="date-toggle"
                  >
                    <fa icon="calendar" />
                    <span
                      aria-hidden="true"
                      class="sr-only"
                    >Toggle</span>
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    title="Clear"
                    data-clear
                  >
                    <i class="fa fa-times" />
                    <span
                      aria-hidden="true"
                      class="sr-only"
                    >Clear</span>
                  </button>
                </div>
              </div>
              <span v-if="errors.date">{{ errors.date }}</span>  
            </div>
      

      
            <div class="form-col-half">
              <label for="broadcastTime">Time<span>*</span></label>
              <div class="input-group">
                <flat-pickr
                  id="broadcastTime"
                  v-model="broadcastTime"
                  :config="bcTimeConfig"
                  class="date-form-control"
                  placeholder="Time"
                  name="broadcastTime"
                  @change="validateTime()"
                />
                    
                <div class="input-group-append">
                  <button
                    type="button"
                    title="Toggle Time"
                    data-toggle="time-toggle"
                  >
                    <fa icon="clock" />
                    <span
                      aria-hidden="true"
                      class="sr-only"
                    >Toggle</span>
                  </button>
                </div>
              </div>
              <span v-if="errors.time">{{ errors.time }}</span>  
            </div>
          </div>


          <div class="form-row">
            <div class="form-col-full">
              <div class="form-actions">
                <button
                  v-if="store.state.event.eventFormMode=='Create'"
                  type="button"
                  @click="resetForm"
                >
                  Reset
                </button>
                <button type="submit">
                  {{ store.state.event.eventFormMode=='Create'?'Save':'Update' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
  
  
  
  
<script setup>

import { useStore } from 'vuex';
import { useRoute,useRouter } from 'vue-router';
import {ref,reactive,watch,onMounted,onUpdated} from 'vue'
import { capitalizeString, isoDateformat, formatTime,isoStringDateformat } from '@/helper/index.js';
import { getChapterList, getEventList} from '@/service/helper-service.js';
import businessCategories from '../../business-categories.json';    
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

  
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import {createBroadcast,getAllAlbum,getAttachments} from '@/service/helper-service.js';


import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import MultiSelect from 'primevue/multiselect';

const FilePond = vueFilePond(FilePondPluginFileValidateType,FilePondPluginFileValidateSize, FilePondPluginImagePreview);

  

const route = useRoute();
const router = useRouter();
const store = useStore(); 
  
const form  = ref();
  
const imageArray = ref([]);
const localSource = ref([]); 
const title = ref('');
const message = ref('');
const groupType = ref('Event Based')
const albumArray = ref('');
const forWhom = ref('');

const pond = ref(null);

const myFiles = ref([]);
const messageArea = ref(null);
  

const	timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const broadcastId = ref(route.params.id);
const formHeading = ref('');
const scheduleHeading = ref('');
const gallery = ref('');
const broadcastDate = ref('');
const broadcastTime = ref('');
const errors = reactive({date:'',error:''});
  
const isConverting = ref(false);
const clrTimeout = ref(null);
const saveBroadcast = ref(false);



const selectedIndustry = ref();
const selectedChapter = ref();
const chapterList = ref([]);
const industryList = ref([]);
const eventList = ref([]);
const eventRecipients = ref('');

const today = new Date().toISOString().split('T')[0];

let bcDateConfig = reactive({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	disableMobile: true,
	minDate: broadcastId.value ? null : today
});

let bcTimeConfig = reactive({
	wrap: true, // s
	disableMobile: true,
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true,
	allowSelection:true,
	time_24hr: true
});


  
const schema = yup.object({
	title: yup.string()
		.test('title-validation', 'Title should be under 150 characters', function(value) {
			if (value && value.length > 150) {

				return false; // Validation failed
			}
			return true;
  
		}),
	message:yup.string().required('Please enter Message'),
	eventRecipients:yup.string().required('Please select recipients'),
});



const validateDate = ()=>{
	if (broadcastDate.value =='') {
		errors.date = 'Please enter date';
		return false;
	} else {
		errors.date = '';
		return true;
	}
}

const validateTime = ()=>{
	if (broadcastTime.value =='') {
		errors.time = 'Please select start time';
		return false;
	} else {
		errors.time = '';
		return true;
	}
}


const onSubmitSchedule = async() => {
	console.log(title.value)
	console.log(broadcastDate.value)
	validateDate()
	validateTime()
	if(validateDate() && validateTime()){
		
		let payload = {
			"content": message.value,
		  "title": capitalizeString(title.value),
			"files": imageArray.value,
			"recipient": forWhom.value,
			"scheduleDate": broadcastDate.value,
			"scheduleTime": isoStringDateformat(broadcastDate.value +' '+ broadcastTime.value),
			"industry": Array.isArray(selectedIndustry.value) && selectedIndustry.value.length > 0
				? selectedIndustry.value.map(item => item.name)
				: [],  // Fallback to an empty array if no data
			"chapterId": Array.isArray(selectedChapter.value) && selectedChapter.value.length > 0
				? selectedChapter.value.map(item => item._id)
				: [],  // Fallback to an empty array if no data
			"eventId": eventRecipients.value,
			"createdBy": store.state.authentication.id
  
		};

		if(gallery.value){
			payload.galleryId = gallery.value;
		}else{
			payload.galleryId = null;
		}
	
	
	
		const method = broadcastId.value ? 'put' : 'post';
		const url = broadcastId.value ? `${process.env.VUE_APP_SERVICE_URL}/broadcast/${broadcastId.value}` : `${process.env.VUE_APP_SERVICE_URL}/broadcast`;

		try {
			const response =  await createBroadcast(method,url,payload);
			if(response.status === 200){
		
				saveBroadcast.value = false;
				if(broadcastId.value){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Scheduled updated successfully',
						color:'success',
						timeout:2000});
					// myFiles.value =[];
					router.push({name:'broadcast'});
				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Scheduled successfully',
						color:'success',
						timeout:2000});
	  
					resetForm()
				}
		  
			}
		} catch (error) {
		
		}
	}
}

  
watch(gallery, (newValue) => {
	console.log('gallery',newValue)
	if(newValue != ''){
		imageArray.value = [];
	}

})


watch(isConverting, (oldValue,newValue) => {
	localSource.value
	if(newValue === true){
		// myFiles.value = localSource;
		myFiles.value = localSource.value;
		console.log("GGG",myFiles.value)
	}

})



      
const adjustTextareaHeight = (e) => {
	e.target.style.height = 'auto';
	  e.target.style.height = e.target.scrollHeight + 'px';
};


businessCategories.map((item) => {
	industryList.value.push({
		name: item,
		value: item
	});
})

const onSubmit = async(data) => {
	console.log('data',data);
	saveBroadcast.value = true;
 
	
	let payload = {
		"content": message.value,
		"title": capitalizeString(title.value),
		"files": imageArray.value,
		"recipient": forWhom.value,
		"industry": Array.isArray(selectedIndustry.value) && selectedIndustry.value.length > 0
			? selectedIndustry.value.map(item => item.name)
			: [],  // Fallback to an empty array if no data
		"chapterId": Array.isArray(selectedChapter.value) && selectedChapter.value.length > 0
			? selectedChapter.value.map(item => item._id)
			: [],  // Fallback to an empty array if no data
		"eventId": eventRecipients.value,
		"createdBy": store.state.authentication.id
  
	};
	
		
	if(gallery.value){
		payload.galleryId = gallery.value;
	}else{
		payload.galleryId = null;
	}
  
	if(broadcastDate.value!=''){
		payload.scheduleDate = broadcastDate.value;
		payload.scheduleTime =  isoStringDateformat(broadcastDate.value +' '+ broadcastTime.value);
	}

  
	
	
	const method = broadcastId.value ? 'put' : 'post';
	const url = broadcastId.value ? `${process.env.VUE_APP_SERVICE_URL}/broadcast/${broadcastId.value}` : `${process.env.VUE_APP_SERVICE_URL}/broadcast`;

	try {
		const response =  await createBroadcast(method,url,payload);
		if(response.status === 200){
		
			saveBroadcast.value = false;
			if(broadcastId.value){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Updated successfully',
					color:'success',
					timeout:2000});
				// myFiles.value =[];
				router.push({name:'broadcast'});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Created successfully',
					color:'success',
					timeout:2000});
	  
				resetForm()
			}
		  
		}
	} catch (error) {
		
	}

	
      
};
  
  


  
const resetForm = ()=>{
	form.value.resetForm()  
	myFiles.value =[];
	imageArray.value = [];
	document.querySelector('#message').style.height='auto';
	selectedIndustry.value = [];
	selectedChapter.value = [];
}
  
  
const editBroadcast = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/broadcast/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			console.log('broadcastId',)
			if(response.status === 200){
				let getBroadCastDetails = response.data.data[0];
				title.value = getBroadCastDetails?.title || '';
				message.value = getBroadCastDetails?.content|| '';
				gallery.value = getBroadCastDetails?.galleryId || '';
				selectedIndustry.value = getBroadCastDetails?.industry?.map(item=>({'name':item,'value':item})) || [];
				selectedChapter.value = getBroadCastDetails?.chaptertDetails?.map(item=>({'_id':item._id,'name':item.chapter})) || [];
				eventRecipients.value = getBroadCastDetails?.eventId || '';
				forWhom.value = getBroadCastDetails?.recipient || '';
				broadcastDate.value = getBroadCastDetails?.scheduleDate && isoDateformat(getBroadCastDetails?.scheduleDate);
				broadcastTime.value = getBroadCastDetails?.scheduleTime && formatTime(getBroadCastDetails?.scheduleTime);
				console.log('getBroadCastDetails?.industry?.map(item=>item.name)',getBroadCastDetails?.industry?.map(item=>({'name':item,'value':item})));
				console.log('getBroadCastDetails?.chapter?.map(item=>item.name)',getBroadCastDetails?.chaptertDetails?.map(item=>({'_id':item._id,'value':item.chapter})));
				console.log('test',formatTime(getBroadCastDetails?.scheduleTime));
        
				imageArray.value = getBroadCastDetails?.files || '';

				for(let i=0;i<getBroadCastDetails.files.length;i++){
					loadAttachments(getBroadCastDetails.files[i])
				}
				

			}
		})
}
  

const loadAlbumList = async() =>{
	
	try {
		const response = await getAllAlbum(`/all/gallery`);
		if(response.status === 200){
			console.log("response",response);
	        albumArray.value = response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
				
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}	
	
}


const loadChapterList = async()=>{
	try {
		const response = await getChapterList(`/chapter`);
		if(response.status === 200){
			console.log('GET chapter response',response?.data?.data)
			let chapterData = response?.data?.data;
			chapterList.value =  chapterData?.map(item => ({
				_id: item._id,
				name: item.chapter,
			}));
		}} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}

const loadEventList = async()=>{
	try {
		const response = await getEventList(`/event`);
		if(response.status === 200){
			console.log('Event response',response?.data?.data)
			let eventData = response?.data?.data;
			eventList.value =  eventData?.map(item => ({
				_id: item._id,
				name: item.eventName
			}));
		}} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}

  
onMounted(()=>{
	console.log("ONMOUNTED")
	let messageTextarea = document.querySelector('#message');
	messageTextarea.style.height = 'auto';
	setTimeout(()=>{
		messageTextarea.style.height = document.querySelector('#message').scrollHeight + 'px';
	},500)
		

	

	if(broadcastId.value){
		formHeading.value="Edit BroadCast";
		scheduleHeading.value="Edit Schedule";
		editBroadcast(broadcastId.value);
	}else{
		formHeading.value="Send BroadCast";
		scheduleHeading.value="Create Schedule";
	}
	loadEventList();
	loadAlbumList();
	loadChapterList();
})



const loadAttachments = async(fileName)=>{
	isConverting.value = true;

	try {
		const response = await getAttachments(`/files?name=${fileName.split('/attachments/')[1]}&type=attachments`);
		if(response.status === 200){
			clearTimeout(clrTimeout.value);
			const base64String = response?.data?.data;
			clrTimeout.value= setTimeout(()=> {
				isConverting.value = false;
			},2000)
			localSource.value.push({source:base64toFile(base64String, fileName.split("/").pop().substring(36), getFileExtension(fileName.split('/attachments/')[1])),options:{type:'local',fullUrl:fileName}});
			console.log('localSource',localSource.value);
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
				
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}

}

onUpdated(()=>{
	console.log("updated");
})

const closeScheduleDialog = ()=>{
	store.state.broadcast.showScheduleDialog = false;
}

   


const handleFilePondInit = ()=> {
	console.log('FilePond has initialized');

	// example of instance method call on pond reference
	pond.value.getFiles();
}





const serverOptions = {
	// url: coverUploadUri.value,
	process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {   
		const formData = new FormData();
		formData.append(fieldName, file, file.name);
            
		const request = new XMLHttpRequest();
		request.open('POST', process.env.VUE_APP_SERVICE_URL+'/files?type=attachments');
	
		request.setRequestHeader('Authorization', 'Bearer '+store.state.authentication.token);
		request.upload.onprogress = (e) => {
			progress(e.lengthComputable, e.loaded, e.total);
			
      
		};

		request.onload = function () {
			
			if (request.status == 200) {
				
				// the load method accepts either a string (id) or an object
				
				const response = JSON.parse(request.responseText);
				imageArray.value.push(response?.data);
				load();
			} else {
				// Can call the error method if something is wrong, should exit after
				console.log('error',request.status);
				if(request.status == 404){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:request.statusText,
						color:'danger',
						timeout:2000});
				}else{
					console.log('request',JSON.parse(request.responseText))

					let jsonData = JSON.parse(request.responseText);
					if(jsonData?.message =='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
					    store.commit('authentication/logout',2000);
					}else{
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:jsonData?.message,
							color:'danger',
							timeout:2000});
					}
						
				}

			} 
		};

		request.onerror = () => {
			// Check if the browser indicates a network error
			if (!navigator.onLine) {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Network error ',
					color:'danger',
					timeout:2000});
			} 
		};
	

		request.send(formData);

		return {
			abort: () => {
				// This function is entered if the user has tapped the cancel button
				request.abort();

				// Let FilePond know the request has been cancelled
				abort();
			},
		};

    

	},
	remove: (source, load, error) => {
		// Should somehow send `source` to server so server can remove the file with this source
    
		// error('oh my goodness');
		const filteredItem = myFiles.value.find(item => item.source === source);
		console.log('filteredItem',filteredItem.options.fullUrl)
		const indexToRemove = imageArray.value.findIndex(item => item === filteredItem.options.fullUrl);
		
		console.log('indexToRemove',indexToRemove);
		if (indexToRemove !== -1) {
			imageArray.value.splice(indexToRemove, 1);
		}

		// Should call the load method when done, no parameters required
		load();
	}
	
	
}

function cancelUpdate(){
	router.push({name:'broadcast'});
}

function base64toFile(base64String, fileName, mimeType) {
	// Decode the base64 string to binary data
	const binaryString = atob(base64String);

	// Convert the binary data to an array buffer
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	const byteArray = new Uint8Array(bytes);

	// Create a Blob from the array buffer
	const blob = new Blob([byteArray], { type: mimeType });

	// Create a File object from the Blob with the specified file name
	return new File([blob], fileName, { type: mimeType });
}


function getFileExtension(filename) {
	// Find the last occurrence of the period (.)
	const lastDotIndex = filename.lastIndexOf('.');
	// If a period is found and it's not the last character, extract the substring after it
	if (lastDotIndex !== -1 && lastDotIndex < filename.length - 1) {
		if(filename.substring(lastDotIndex + 1) =='jpg'){
			return 'image/jpeg';
		}

		if(filename.substring(lastDotIndex + 1) =='png'){
			return 'image/png';
		}

		if(filename.substring(lastDotIndex + 1) =='pdf'){
			return 'application/pdf';
		}

		if(filename.substring(lastDotIndex + 1) =='docx'){
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		}
	}
	// If no period is found or it's the last character, return an empty string
	return '';
}
  

const openSchedule = ()=>{
	if(message.value =='' && eventRecipients.value == ''){
		store.commit('snackbar/showSnackbar',{
			show:true,
			message:'Please fill all the required fields before schedule ',
			color:'danger',
			timeout:2000});
	}else{
		store.state.broadcast.showScheduleDialog = true;
	}
	
}


</script>
  
  
  
  
  <style scoped>
  .form-container{display:flex;}
  .form-card{box-shadow: 0px 2px 12px 0px #80808040;
   border-radius: 12px;width: 97%;margin: 12px;
  background-color:white;padding: 17px;
 
 }
 .form-card:nth-child(1){
  flex-basis:55%;
 }
 .form-card:nth-child(2){
  flex-basis:45%
 }
 .form-sets{min-height:100%;}
  .form-card h3{margin-bottom: 15px;}
  .form-card h4{margin: 12px 0;}
  .file-limitation{color: gray;font-size: 14px;font-weight: 300;}
  .form-row{display: flex;}
  label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
  label span{color:red;}
  input + span ,select + span ,textarea + span ,.input-group + span,.vue-tel-input + span,.p-editor-container + span{color:red;text-align: left;font-size: 12px;}
  .form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-bottom: 14px;}
  .form-col-half{margin-right:30px;}
  .form-col-half:last-child{margin-right:0px;}
  .form-profile{flex-basis: 20%;margin-right: 15px;}
  .profile-container{position: relative; width:170px;height: 170px;border-radius: 50%;margin: auto;
      display: block;}
  
      
      
  .title{flex-basis: 14%!important;flex:unset;}
  .form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;
      height: 38px;padding: 4px 13px;width:100%;text-transform: capitalize;}
   .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus,.form-col-full textarea{outline: unset;}   
   select{border: 1px solid #B2B2B2;
      padding: 6px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:black;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  .form-actions{display: flex;justify-content: flex-end;}
  .form-actions button{background-color: #007CBA;
      color: white;
      border-radius: 7px;
      padding: 6px 28px;
      margin-left: 18px;}
  .error-msg{text-align: left;
      color: red;
      font-size: 12px;}
  :deep() .iti__selected-flag{background-color:unset;}
  
  /* date picker */
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  :deep() .form-col-half  .date-form-control{
    width: 100%;height: 37px;padding: 12px;}
  
   :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
    
    .input-group-append {
    display: flex;
    align-items: center; /* Aligns buttons vertically */
    /* Add additional styles as needed */
  }
  .input-group-append button{margin-right: 7px;}
  .input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
  .radio-group{display: flex;}
  .radio-group input{margin-right: 8px;margin-top: -4px;}
  :deep() .upload-icon{position:relative;margin-top: 42px;}
    :deep() .upload-icon::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}  
	
	:deep() .filepond--drop-label{min-height: 12.75em;border:2px dashed #C5C5C5;border-radius: 9px;}
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
  .radio-group > label{margin-right:12px;}
  .p-multiselect:not(.p-disabled).p-focus{border-color: gray;}


/* custom scheduler popup */
  .dialog-title{display: flex;align-items: center;}
.dialog-title .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
 .dialog-body{margin-top: 12px;font-size: 16px;font-weight: 500;}   
.dialog-action{display: flex;justify-content: flex-end;}
.dialog-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.danger{background-color: #D92D20;color: white;border-color:#D92D20;}
.success{background-color: white;border: 1px solid #8BC34A;
    color: #8BC34A;}
.info{background-color: #007CBA;border: 1px solid #007CBA;
    color: white;}    
.dialog-action button{padding: 8px 13px;
    border-radius: 9px;
    border: 1px solid;}

    .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.dialog {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 498px;
}
.close-icon{position: absolute;right: 19px;top: 15px;cursor: pointer;}
.schedule-form h2{font-size: 18px;}
/* custom scheduler popup */

  @media only screen and (max-width: 768px) {
    .form-container{flex-direction: column;}
    .form-card:nth-child(1),.form-card:nth-child(2){flex-basis: 100%;width:unset;}
    .form-row{flex-direction: column;}
    .form-col-half, .form-col-full{margin-right: unset;}
  }
  </style>
  
  