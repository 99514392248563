<template>
  <v-dialog 
    v-model="showEditEventDialog"
    width="500"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <div class="heading-container">
            <h3>
              Edit Event
            </h3>
            <span> ID:{{ store.state.event.uniqeEventId }}</span>
          </div>
          
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="title">
                  <label for="title">Title <span>*</span></label>
                  <Field
                    v-model="title"
                    type="text"
                    name="title"
                    placeholder="Title"
                  />
                  <ErrorMessage name="title" />
                </div>

                <div class="tag">
                  <label for="tag">Tag</label>
                  <Field
                    v-model="tag"
                    type="text"
                    name="tag"
                    placeholder="Tag"
                  />
                  <ErrorMessage name="tag" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label for="description">Description</label>
                  <Field
                    v-model="description"
                    name="description"
                    cols="30"
                    rows="3"
                    as="textarea"
                    placholder="Description"
                  />
                  <ErrorMessage name="description" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label
                    for="keywords"
                  >Keywords</label>
                  <Field
                    v-model="keywords"
                    type="text"
                    name="keywords"
                    placeholder="Keywords"
                  />
                  <ErrorMessage name="keywords" />
                </div>
              </div>

              <div class="form-row">
                <div
                  v-if="store.state.event.venueId!==null"
                  class="form-col-full"
                >
                  <label for="hall">Hall</label>
                  <Field
                    id="hall"
                    v-model="hall"
                    name="hall"
                    as="select"
                  >
                    <option value="">
                      Hall
                    </option>
                    <option
                      v-for="item in store.state.event.hallList"
                      :key="item._id"
                      :value="item._id"
                    >
                      {{ item.name || '' }}
                    </option>
                  </Field>
                  <ErrorMessage name="hall" />
                </div>
              </div>

              <div class="form-row">
                <div
                  v-if="store.state.event.venueId==null"
                  class="form-col-full"
                >
                  <label
                    for="meetLink"
                  >Meet Link</label>
                  <Field
                    v-model="store.state.event.meetLink"
                    type="text"
                    name="meetLink"
                    placeholder="Meet Link"
                  />
                  <ErrorMessage name="meetLink" />
                </div>
              </div>

             
              

              <div class="form-row">
                <div class="form-col-full">
                  <label for="speakers">Speakers</label>
                  <Field
                    v-model="speakers"
                    name="speakers"
                    type="text"
                    readonly
                    @click="showMemberDropdown"
                  />
                  <div
                    v-show="dropdownEnabled==true"
                    class="member-list-wrapper"
                  >
                    <div class="search-icon">
                      <fa icon="search" />
                      <input
                        id="search_member"
                        type="text"
                        placeholder="Search"
                        @keyup="searchSpeaker"
                      >
                    </div>
                    <div class="member-list-dropdown">
                      <div
                        v-for="item in speakerList"
                    
                        :key="item.id"
                      >
                        <label
                        
                          :for="item?._id"
                          style="display: block;"
                          class="speaker-name"
                          :class="speakerExist(item?._id)?'active':''"
                        ><input
                          :id="item?._id"
                          type="checkbox"
                          :data-id="item?._id"
                          :value="joinName(item?.firstName,item?.middleName,item?.lastName)"
                          @change="addSpeakers"
                        > {{ truncateString(joinName(item?.firstName,item?.middleName,item?.lastName),25) }}</label>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage name="speakers" />
                </div>
              </div>  




              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>
import {ref, inject, watch, onMounted} from 'vue'
import moment from 'moment';
import { useStore } from 'vuex';
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import { formatDuration, joinName, truncateString, isoStringDateformat } from '@/helper/index.js';
import { getAllSpeaker, getHallList, addEventTopic, getTopicDetail} from '@/service/helper-service.js';


const store = useStore(); 


// const props = defineProps({
// 	eventId:{
// 		type:String,
// 		required:false,
// 		default:''
// 	},
// 	eventDates:{
// 		type:Array,
// 		required:true,
// 		default:()=>{[]}
// 	},
// 	mode:{
// 		type:String,
// 		required:false,
// 		default:()=>''
// 	},
// 	venueId:{
// 		type:String,
// 		required:false,
// 		default:()=>''
// 	}
// })



let showEditEventDialog =  ref(false);

let openEditEventDialog = inject('openEditEventDialog');




const form = ref(null);
const title = ref('');
const tag = ref('');
const description = ref('');
const keywords = ref('');
const hall = ref('');
const speakers = ref('');
const selectedSpeaker = ref([]);
const speakersId = ref([]);
const track = ref('');
const duration = ref('');
const date = ref('');
const time = ref('');
const options = ref([]);
const speakerList = ref([]);
const hallList = ref([]);
const trackList = ref([]);

const snackbar= ref(false);
const text= ref('');
const timeout = ref(2000);
const imageUrl = ref('');
const serverName = ref('');
const isDateDisabled = ref(true); 
const isTimeDisabled = ref(true); 




const dropdownEnabled = ref(false);

const showMemberDropdown = ()=>{
	dropdownEnabled.value = !dropdownEnabled.value; 
}

const addSpeakers = (e)=>{
	const index = selectedSpeaker.value.indexOf(e.target.value);

	if (index > -1) { 
		selectedSpeaker.value.splice(index, 1); 
	}else{
		selectedSpeaker.value.push(e.target.value);
	}

	speakers.value = selectedSpeaker.value.join(", ");

	//for speakers id
	const idIndex = speakersId.value.indexOf(e.target.getAttribute('data-id'));
	if (idIndex > -1) { 
		speakersId.value.splice(idIndex, 1); 
		e.target.parentNode.classList.remove('active')
	}else{
		speakersId.value.push(e.target.getAttribute('data-id'));
		e.target.parentNode.classList.add('active')
	}
	console.log("speakersId",speakersId.value)
}

const searchSpeaker = (e)=>{
	console.log("e",e.target.value);

	const speakerName = document.querySelectorAll(".speaker-name"); // Replace with your own selector
	for (let elem of speakerName) {
		if (elem.textContent.toLowerCase().indexOf(e.target.value) != -1) {
			elem.style.display = 'block';
		} else {
			elem.style.display = 'none';
		}
	}
	
}




const speakerExist = (speakerId) =>{
	return speakersId.value.find(id=> id == speakerId) !== undefined;
}


watch(openEditEventDialog, (newValue) => {
	console.log("newValue",newValue.status);
	showEditEventDialog.value = newValue.status
});



watch(() => store.state.event.editScheduleData, (newValue) => {
	console.log("editID",newValue);
	if(newValue !=''){
		title.value = newValue?.title;
		tag.value = newValue?.tag;
		description.value = newValue?.description;
		keywords.value = newValue?.keywords;
		hall.value = newValue?.hallId;
		speakersId.value = newValue?.speakers;
		const speakerNames = newValue?.speakers
			.map(id => {
				const speaker = speakerList.value.find(speaker => speaker._id == id);
				return joinName(speaker?.firstName,speaker?.middleName,speaker?.lastName);
			})
		selectedSpeaker.value = [...speakerNames];
		speakers.value = [...speakerNames];
		// editScheduleDetail(newValue)
	} 
  
});

// watch(() => store.state.event.venueId, (newValue) => {
// 	if(newValue !=''){
// 		loadHalls(newValue);
// 	} 
  
// });









// onBeforeMount(()=>{
//   topicDateConfig.enable=store.state.event.eventDates;
// })
  
onMounted(() => {
  
	serverName.value = window.location.origin;
	imageUrl.value = require(`@/assets/images/avatar.svg`);
	loadSpeakers()

	
	const durationOptions = [];
	for (let i = 15; i <= 240; i += 15) {
		durationOptions.push({
			value: i,
			text: formatDuration(i),
		});
	}
	options.value = durationOptions;

  
})

  





const closeDialog = ()=>{
	// resetForm();
	openEditEventDialog.status =false;
	showEditEventDialog.value =false;
}





const schema = yup.object({
	title:yup.string().required('Please select title')
});



const onSubmit = async(data) => {
	console.log("DATA",data);
	let payload = {
		"description": data.description,
		"title": data.title,
		"tag": data.tag,
		"keywords": data.keywords,
		"speakers": speakersId.value || [],
		...(data.track && { tracks: [data.track] }),
		...(data.hall && { hallId: data.hall } ),
	}
	console.log("payload",payload);

	let index = store.state.event.events.findIndex(event => event.id == store.state.event.editScheduleData.id);
	store.state.event.events[index].title = data.title;
	store.state.event.events[index].tag = data.tag;
	store.state.event.events[index].description = data.description;
	store.state.event.events[index].keywords = data.keywords;
	store.state.event.events[index].hallId = hall.value;
	store.state.event.events[index].speakers = speakersId.value;
	console.log('index', store.state.event.events[index])
      
	
	// resetForm()
	openEditEventDialog.status = false;
	store.commit('snackbar/showSnackbar',{
		show:true,
		message:'Schedule updated Successfully',
		color:'success',
		timeout:2000});
};


const editScheduleDetail = async(id)=>{
	try {
		const response = await getTopicDetail(`/schedule/${id}`);
		if(response.status === 200){
			console.log('EDit response',response?.data?.data?.[0]);
			let fetchResult = response?.data?.data?.[0];
			title.value = fetchResult?.title;
			tag.value = fetchResult?.tag;
			description.value = fetchResult?.description;
			keywords.value = fetchResult?.keywords;
			hall.value = fetchResult?.hallId;
			speakersId.value = fetchResult?.speakers;
			const speakerNames = fetchResult.speakers
				.map(id => {
					const speaker = speakerList.value.find(speaker => speaker._id == id);
					return joinName(speaker?.firstName,speaker?.middleName,speaker?.lastName);
				})
			selectedSpeaker.value = [...speakerNames];
			speakers.value = [...speakerNames];
		}
	} catch (error) {

	}
}

// const loadHalls = async (id) =>{
// 	try {
// 		const response = await getHallList(`/hall?venueId=${id}`);
// 		if(response.status === 200){
// 			store.state.event.hallList = response?.data?.data;
// 		}
// 	} catch (error) {
	
// 	}

// }

const loadSpeakers = async () =>{
	try {
		const response = await getAllSpeaker(`/speaker`);
		if(response.status === 200){
			speakerList.value = response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

}

const resetForm = ()=>{
	store.state.event.editTopicId = '';
	speakers.value ='';
	selectedSpeaker.value = [];
	speakersId.value = [];
	form.value.resetForm();

	// emit('submit','success');
	// tableRefresh.status=true;
}





</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] + span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.title{flex-basis: 70%;margin-right: 18px;}
.tag{flex-basis: 30%;}
.speakers{flex-basis: 66%;margin-right: 18px;}
.track{flex-basis: 31%;}
.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.title input,.tag input,.speakers input,.track select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}
.heading-container{display: flex;align-items: center;justify-content: space-between;margin: 11px 0;}
.heading-container > span{text-align: right;margin-right: 12px;font-weight: 700;}
/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}

.member-list-wrapper{border: 1px solid #b2b2b2;
    border-radius: 5px;margin-top: 12px;}
.member-list-dropdown{position: relative;height:180px;overflow-y:auto;}
  .member-list-dropdown::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.member-list-dropdown::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}
.member-list-dropdown::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-list-dropdown label{cursor: pointer !important;border-top: 1px solid #b4b4b4;
    padding: 8px 12px;margin-bottom: 0;}
.member-list-dropdown input{display:none;}
#search_member{border:unset;}
.search-icon{display: flex;align-items: center;background-color: #f5f4f4;border-radius: 3px 3px 0 0;}
.search-icon .fa-magnifying-glass{margin-left: 12px;color: #b2b2b2;}
:deep() label.active{background-color:#ecf2f6;transition: .2s ease-in-out;}
</style>
