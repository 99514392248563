<template>
  <AddChapter />
  <div class="tab-heading-and-action">
    <div>
      <h3>Master Chapter </h3>
    </div> 
    
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    
      <div
        class="add-speakers"
        @click="clickChapterDialog"
      >
        <fa icon="plus" /> Add Chapter
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.about`]="{ item }">
      <div :class="item.about">
        {{ limitStringLength(item.about,50) }}
      </div>
    </template>

    <template #[`item.action`]="{ item }">
      <fa
        icon="pen-to-square"
        class="pointer"
        @click="editChapter(item.view)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteChapterConfirmation(item.action)"
      />
    </template>
  </v-data-table-server>

  
  <!-- delete dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this speaker? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteChapter">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete dialog -->

    
      
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
  >
    <div class="view-member-modal">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="viewDialog = false"
        />
        <h3>Chapter Details</h3>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Full Name</h5>
          <p>{{ speakerView.fullName }}</p>
          <h5>Designation</h5>
          <p>{{ speakerView.designation }}</p>
          <h5>About</h5>
          <p>{{ speakerView.about }}</p>
        </div>
      </div>
    </div>
  </div>


  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
import AddChapter from '@/components/chapter_list/AddChapter.vue';
import {ref,reactive,watch,provide, onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import axios from 'axios';
import {getChapterList,removeChapter} from '@/service/helper-service.js';




const store = useStore(); 
const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Chapter', value: 'chapter' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const chapterId=ref('');
const mode =  ref('add');
const speakerView = reactive({
	fullName:'',
	designation:'',
	about:''
})

const name= ref('');
const	search= ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);
const tableHeight = ref(0);
// const refreshStatus = ref(tableRefresh);


let openChapterDialog = reactive({'status':false});
provide('openChapterDialog',openChapterDialog)
provide('chapterId',chapterId)
provide('mode',mode)
// let formSubmitted =  inject('formSubmitted');

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});

watch(() => store.state.chapter.isChapterUpdated, (newValue) => {
	if(newValue==true){
		search.value = String(Date.now())
		store.state.chapter.isChapterUpdated = false;
	}

});




const clickChapterDialog = () =>{
	store.state.chapter.showAddChapterDialog = true;
}

onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

const loadItems = async({ page, itemsPerPage, sortBy })=>{

	offset.value = (page - 1) * itemsPerPage
	loading.value = true

	try {
		const response = await getChapterList(`/chapter?limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});

		console.log('response.data',response.data);

		totalItems.value = response?.data?.totalCount;
		loading.value = false;

		let chapterData = response?.data?.data;
		console.log('chapterData',chapterData);
		serverItems.value =  chapterData.map(item => ({
			chapter: item.chapter?item.chapter:'-',
			action:item._id?item._id:'-',
			view:item
		}))

	} catch (error) {
		console.error('chapter error:', error);
	
	}

  

}

const	deleteChapterConfirmation = (item) =>{
	chapterId.value = item;
	deleteDialog.value=true;
}

const deleteChapter = async()=>{

	try {
		const response = await removeChapter(`/chapter/${chapterId.value}`);
		if(response.status === 200){
			search.value = String(Date.now());
			deleteDialog.value=false;
			viewDialog.value=false;
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}


	
}

	




const editChapter = (data)=>{
	store.state.chapter.showAddChapterDialog = true;
	store.state.chapter.chapterFormMode = 'Edit';
	chapterId.value = data._id;
	store.state.chapter.editChapterData = data;
}

const limitStringLength = (inputString, maxLength)=> {
	if (inputString.length > maxLength) {
		// If the string is longer than the specified maxLength, truncate it
		// and add '...' at the end.
		return inputString.slice(0, maxLength) + '...';
	} else {
		// If the string is within the specified maxLength, return it as is.
		return inputString;
	}
}


const messageFromChild = ref('');

const receiveMessageFromChild = (message) => {
	// Receive the message from the child component
	messageFromChild.value = message;
	console.log("message",message);
};



</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
.search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
.search-container input{outline: unset;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th:nth-last-child(1){width: 152px;
  text-align: center;}
  .v-table :deep() thead tr > th:nth-last-child(1) .v-data-table-header__content{justify-content: center;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td:nth-last-child(1){text-align: center;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}

</style>






